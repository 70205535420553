import { implant_background, implant_bone_section1, implant_bone_section2, implant_bone_section3, implant_implant_section1, implant_implant_section2, implant_implant_section3_background, implant_implant_section3_item1, implant_implant_section3_item2, implant_implant_section3_item3, implant_implant_section3_logo1, implant_implant_section3_logo2, implant_MaxillarySinusFloorElevation_section1, implant_MaxillarySinusFloorElevation_section2, slide_big_next, slide_next, slide_next_primary, slide_prev, slide_prev_primary } from '@/assets'
import { GridBox, PageTitle } from '@/components'
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from 'react';
import useOnePageScroll from '@/hooks/useOnePageScroll';



const Container = styled.div`
  padding-top: 80px;
  
  .innerSection {
    padding: 160px 0;
    width: 1180px;

    
    .slideCover {
      width: calc(100%);
    }
     
    .btnBox {
      top: 50px;
      right: 0;
    }
  }

  .section {
    margin-bottom: 160px;

    .content {
      letter-spacing: -1px;
    }
   
  }



  .logo1 {
    top: 32px;
    left: 32px;
  }

  .logo2 {
    bottom: 38px;
    right: 38px;
  }

`



const StyledSlider = styled(Slider)`
  height: fit-content;
  .slide-item {
    .img {
      width: 570px;
      height: 367px;
    }

    .arrow {
      width: 26px;
    }
  }
`

const TotalPage = 9

export default function Page () {
  const pageScrollItem = useOnePageScroll()

  const options = {
    className: "slider variable-width",
    arrows:false,
    speed: 200,
    infinite: true,
    cssEase:'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    draggable: false,
  };

  const ref = useRef()

  return (
    <Container className='flex-col align-center' {...pageScrollItem}>
      <PageTitle
        nav="임플란트"
        sub="상악동 거상술"
        background={implant_background}
        title="상악동 거상술"
        subTitle="Maxillary Sinus Floor Elevation"
      />


      <div className='innerSection' id="anker">
        <div className='section flex-center'>
          <div className='flex-1'>
            <div className='MaruBuri font-22 c-brown02 mb-12'>Maxillary Sinus Floor Elevation</div>
            <div className='mb-48 MaruBuri font-42 semibold line-50'>상악동 거상술</div>

            <div className='c-black02 font-20 line-32 content keep-all'>
            “상악동”이란 우리 얼굴의 위 턱뼈(상악골)의 윗부분 양쪽에 위치한 공기가 차 있는 피라미드 형태의
            구조물로, 좌 우측 상악동은 각각 비강(콧구멍)과 연결되어 있습니다. 상악동은 오른쪽 사진에서처럼 
            대개 작은 어금니에서 큰 어금니 상방에 걸쳐져 존재하는데, 선천적으로 이 상악동이 아래로 쳐져 
            있거나 치주염이 심해서 치아 뿌리 끝까지 잇몸뼈가 녹으면 상악동 하방의 잇몸 뼈 높이가 별로 
            남아있지 않게 됩니다.<br/><br/>

            이처럼 임플란트 심기에 잇몸뼈 높이가 부족한 상태에서는 상악동 기저부 치밀골을 깨고 내면에 있는
            상악동 막을 들어올려서 임플란트를 나사를 심을 수 있는데, 이 술식을 ‘상악동 거상술’ 이라고 합니다.
            상악동 거상술과 임플란트 나사 심는 수술을 동시에 하기도 하지만, 치조골이 많이 녹아 거의 남아있지 
            않을 ​때에는, 상악동 거상과 뼈이식을 해놓고 6~8개월 정도 기다렸다가 다시 임플란트 수술을 합니다.
            </div>
          </div>
          
          <img src={implant_MaxillarySinusFloorElevation_section1} style={{marginLeft: '120px', width: '500px'}}/>
        </div>


        <div className='section'>
          <div className='w-full mb-48'>
            <div className='MaruBuri font-22 c-brown02 mb-12'>Maxillary Sinus Floor Elevation</div>
            <div className='mb-48 MaruBuri font-42 semibold'>상악동 거상에도 사용되는 자가골 이식</div>

            <div className='c-black02 font-20 line-32 content keep-all'>
              기쁜마음치과의 자가골 이식은 블록뼈 형태뿐 아니라, 상악동 거상에도 사용됩니다. 장점으로는 뼈이식재 사용으로 인한 상악동 거상 후 비염 발생률이 0% 이며 성장인자(BMPs), 그리고 조골 세포 등
              좋은 성분으로 채워져있는 살아있는 조직인 자가골을 이식함으로써 임플란트 나사 주변의 뼈가 빨리 단단해지기 때문에 상악동 거상술 후 임플란트 치료 기간이 짧습니다. 상악동 거상에는 사진과 같은 
              대패밥 형태의 자가골(Autogenous Particulated Bone)을 사용합니다.
            </div>
          </div>
          
          <img className='w-full' src={implant_MaxillarySinusFloorElevation_section2}/>
        </div>

        <div className='w-full relative'>
          <div className='MaruBuri font-22 c-brown02 mb-12'>Treatment Case</div>
          <div className='mb-48 MaruBuri font-42 semibold'>상세 치료사례</div>

          <div className='flex-center absolute btnBox'>
            <img src={slide_prev_primary} className='mr-30 pointer' onClick={() => ref?.current?.slickPrev()}/>
            <img src={slide_next_primary} className='pointer' onClick={() => ref?.current?.slickNext()}/>
          </div>

          <div className='slideCover'>
            <StyledSlider ref={ref} {...options}>
              {
                [...new Array(Math.ceil(TotalPage/2))].map((post, key) => (
                  <div className='slide-item' key={`list2-post-key-${key}`}>
                    <div className='flex-center mb-28'>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/MaxillarySinusFloorElevation/before${key * 2 + 1}.png`}/>
                      <img className='mx-6 arrow' src={slide_big_next}/>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/MaxillarySinusFloorElevation/after${key * 2 + 1}.png`}/>
                    </div>

                    {key === 0 ? <div className='pt-12'/> : <div className='flex-center mb-28'>
                      <img className='text' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/MaxillarySinusFloorElevation/text${key * 2 + 1}.png`}/>
                    </div>}
                    
                    {(key * 2 + 2) <= TotalPage && <>
                      <div className='flex-center mb-28'>
                        <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/MaxillarySinusFloorElevation/before${key * 2 + 2}.png`}/>
                        <img className='mx-6 arrow' src={slide_big_next}/>
                        <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/MaxillarySinusFloorElevation/after${key * 2 + 2}.png`}/>
                      </div>

                      <div className='flex-center'>
                        <img className='text' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/MaxillarySinusFloorElevation/text${key * 2 + 2}.png`}/>
                      </div>
                    </>}
                  </div>
                ))
              }
            </StyledSlider>
          </div>
        </div>
      </div>
    </Container>
  )
}