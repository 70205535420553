import {
  main_section1_background,
  main_section1_scroll,
  main_section1_text,
} from "@/assets";
import { useScroll, useScrollFadeIn } from "@/hooks";
import { getTheme } from "@/styles/theme";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import Typewriter from "typewriter-effect";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 60px);

  background-image: url(${main_section1_background});
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  .section_title {
  }

  .scroll_btn {
    bottom: 50px;
  }

  .title_background {
    background-color: ${getTheme("primary")};
    animation-duration: 2s;
    animation-delay: 1s;
    animation-name: slidein;
    transition-delay: 2s, 4ms;
    height: 100%;
    animation-fill-mode: forwards;
    top: 0;
    left: 0;
  }

  @keyframes slidein {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }
`;

export default function Section1() {
  const animatedItem1 = useScrollFadeIn();
  const animatedItem2 = useScrollFadeIn();
  const animatedItem3 = useScrollFadeIn();
  const animatedItem4 = useScrollFadeIn();

  function goSection2() {
    let element = document.getElementById("main_section_2");
    let top = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      behavior: "smooth",
      top,
    });
    document.body.style = `overflow: auto`;
  }

  return (
    <Container className='flex-col flex-center relative' id='main_section_1'>
      <div
        className='section_title relative px-12 py-10 c-white font-12 mb-20'
        {...animatedItem1}
      >
        <div className='title_background absolute' />

        <span className='highlight relative'>
          긴 세월 동안 치료실패 혹은 부작용 발생확률은 최소화
        </span>
      </div>

      <div
        className='font-36 MaruBuri text-center semibold c-white line-42'
        {...animatedItem2}
      >
        저희는
        <br />
        기쁜마음치과의원
        <br />
        입니다.
      </div>

      <div
        className='MaruBuri semibold c-white line-22 text-center mt-20 mb-44'
        {...animatedItem3}
      >
        28년 동안 미국과 한국에서
        <br />
        대학병원 포함 2,500건 이상의
        <br />
        고난이도 치주 및 임플란트 수술을
        <br />
        성공시켰습니다.
      </div>

      <img
        src={main_section1_text}
        className='mb-80 pointer'
        {...animatedItem4}
        onClick={goSection2}
        style={{ width: "245px" }}
      />

      <div className='absolute scroll_btn'>
        <img
          className='pointer'
          src={main_section1_scroll}
          onClick={goSection2}
        />
      </div>
    </Container>
  );
}
