import {
  implant_background,
  implant_implant_section1,
  implant_implant_section2,
  implant_implant_section3_background,
  implant_implant_section3_item1,
  implant_implant_section3_item2,
  implant_implant_section3_item3,
  implant_implant_section3_logo1,
  implant_implant_section3_logo2,
  periodontalsurgery_background,
  periodontalsurgery_gummy_m_section2,
  periodontalsurgery_gummy_section1,
  periodontalsurgery_gummy_section2,
  slide_big_next,
  slide_next,
  slide_next_primary,
  slide_prev,
  slide_prev_primary,
  periodontalsurgery_gummy_m_section2Fix,
} from "@/assets";
import { GridBox, PageTitle } from "@/components";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from "react";
import useOnePageScroll from "@/hooks/useOnePageScroll";

const Container = styled.div`
  padding-top: 60px;

  .innerSection {
    padding: 60px 20px;

    .slideCover {
      width: calc(100%);
    }

    .btnBox {
      top: 50px;
      right: 0;

      img {
        width: 20px;
      }
    }
  }

  .section {
    margin-bottom: 60px;

    .content {
      letter-spacing: -1px;
    }
  }

  .logo1 {
    top: 32px;
    left: 32px;
  }

  .logo2 {
    bottom: 38px;
    right: 38px;
  }

  .lowImg {
    width: 100%;
  }

  .text {
    height: 24px;
  }
`;

const StyledSlider = styled(Slider)`
  width: 100%;

  .slide-item {
    width: 100% !important;

    .img {
      width: calc(50vw - 32px);
    }

    .arrow {
      width: 12px;
    }
  }
`;

const TotalPage = 10;

export default function Page() {
  const pageScrollItem = useOnePageScroll();

  const options = {
    className: "slider variable-width",
    arrows: false,
    speed: 200,
    infinite: true,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    draggable: false,
  };

  const ref = useRef();

  return (
    <Container className="flex-col align-center" {...pageScrollItem}>
      <PageTitle
        nav="치주수술"
        sub="심미 치관 연장술・거미 스마일"
        background={periodontalsurgery_background}
        title={`심미 치관 연장술・\n거미 스마일`}
        subTitle={`Esthetic Crown Lengthening・\nGummy Smile`}
        isMobile={true}
      />

      <div className="innerSection w-full" id="anker">
        <div className="section">
          <div className="w-full">
            <div className="MaruBuri font-12 c-brown02 mb-8">
              Esthetic Crown Lengthening・Gummy Smile
            </div>
            <div className="mb-20 MaruBuri font-24 line-32 semibold">
              심미 치관 연장술・거미 스마일
            </div>

            <div className="c-black02 font-14 line-22 content keep-all">
              천사처럼 사랑스럽고 아름다운 톱 모델 나탈리아 보디아노바는 거미
              스마일이지만, 보통 잇몸이 많이 보이면 외모가 덜 심미적으로
              보여집니다. <br />
              <br />
              치아도 가장 예뻐 보이는 황금비율이 있듯이, 웃을때 2mm 정도 보이는
              잇몸을 정상으로 보며 이보다 잇몸이 더 보이는 상태를 Gummy
              Smile(거미 스마일)이라고 합니다. 거미 스마일은 많이 보이는 잇몸
              이외에도 짧은 치아, 두툼한 잇몸뼈, 튀어나온 입, 웃을때 윗 입술
              뒤집어짐 등의 현상들이 나타납니다.
              <br />
              <br />
              원인은 잇몸 속에 있는 잇몸뼈가 치아를 비정상적으로 많이 덮고 있는
              ‘Delayed Passive Eruptioin’ 때문입니다.
              <br />
              <br />
              이를 해결하기 위해서는 단순히 잇몸을 잘라내는 것이 아닌, 잇몸을
              열고 치아를 비정상적으로 많이 덮고 있는 잇몸뼈(치조골)의 길이와
              튀어나온 뼈의 외형을 정상적인 형태로 다듬는 수술인
              심미치관연장술(Esthetic Crown Lengthening)이 필요합니다.
            </div>
          </div>

          <img
            className="w-full mt-40"
            src={periodontalsurgery_gummy_section1}
          />
        </div>

        <div className="section">
          <div className="w-full mb-48">
            <div className="MaruBuri font-12 c-brown02 mb-8">Process</div>
            <div className="mb-20 MaruBuri font-24 line-32 semibold">
              치료과정
            </div>

            <div className="c-black02 font-14 line-22 content keep-all">
              단순히, 앞니 잇몸만 잘라내는 것이 아니고 뒤쪽 어금니의 잇몸까지
              들어올려 잇몸 뼈의 길이 및 두께까지 전체적으로 조화를 이루도록
              수술합니다. 잇몸 형태는 잇몸 뼈의 높이와 모양에 따라 결정되기에
              잇몸뼈를 수술하지 않고 칼이나 레이저로 잇몸만 잘라내면 100% 거미
              스마일이 재발하기 때문입니다. 수술 직후 봉합했을 당시와 '아래 사진
              맨 우측’ 6개월 후의 치아 길이나 잇몸 형태에 별 차이가 없습니다.
            </div>
          </div>

          <img
            className="w-full"
            src={periodontalsurgery_gummy_m_section2Fix}
          />
        </div>

        <div className="w-full relative">
          <div className="MaruBuri font-12 c-brown02 mb-8">Treatment Case</div>
          <div className="flex align-center mb-20 ">
            <div className="MaruBuri font-24 line-32 semibold">
              상세 치료사례
            </div>
            <div className="flex-1" />
            <div className="flex align-center btnBox">
              <img
                src={slide_prev_primary}
                className="mr-16 pointer"
                onClick={() => ref?.current?.slickPrev()}
              />
              <img
                src={slide_next_primary}
                className="pointer"
                onClick={() => ref?.current?.slickNext()}
              />
            </div>
          </div>

          <div className="slideCover">
            <StyledSlider ref={ref} {...options}>
              {[...new Array(TotalPage / 2)].map((post, key) => (
                <div className="slide-item" key={`list2-post-key-${key}`}>
                  <div className="flex-center">
                    <img
                      className="img"
                      src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontalSurgery/gummy/before${
                        key * 2 + 1
                      }.png`}
                    />
                    <img className="mx-6 arrow" src={slide_big_next} />
                    <img
                      className="img"
                      src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontalSurgery/gummy/after${
                        key * 2 + 1
                      }.png`}
                    />
                  </div>

                  <div className="flex-center mt-10">
                    <img
                      className="img"
                      src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontalSurgery/gummy/before${
                        key * 2 + 2
                      }.png`}
                    />
                    <img className="mx-6 arrow" src={slide_big_next} />
                    <img
                      className="img"
                      src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontalSurgery/gummy/after${
                        key * 2 + 2
                      }.png`}
                    />
                  </div>
                </div>
              ))}
            </StyledSlider>
          </div>
        </div>
      </div>
    </Container>
  );
}
