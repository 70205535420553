import { implant_background, implant_bone_section1, implant_bone_section2, implant_bone_section3, implant_complications_m_section2, implant_complications_m_section3, implant_complications_section1_1, implant_complications_section1_2, implant_complications_section2, implant_complications_section3, implant_implant_section1, implant_implant_section2, implant_implant_section3_background, implant_implant_section3_item1, implant_implant_section3_item2, implant_implant_section3_item3, implant_implant_section3_logo1, implant_implant_section3_logo2, implant_MaxillarySinusFloorElevation_section1, implant_MaxillarySinusFloorElevation_section2, slide_big_next, slide_next, slide_next_primary, slide_prev, slide_prev_primary } from '@/assets'
import { GridBox, PageTitle } from '@/components'
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from 'react';
import useOnePageScroll from '@/hooks/useOnePageScroll';




const Container = styled.div`
  padding-top: 60px;
  
  .innerSection {
    padding: 60px 20px;

    
    .slideCover {
      width: calc(100%);
    }
     
    .btnBox {
      top: 50px;
      right: 0;
      
      img {
        width: 20px;
      }
    }
  }

  .section {    
    margin-bottom: 60px;


    .content {
      letter-spacing: -1px;
    }
   
  }



  .logo1 {
    top: 32px;
    left: 32px;
  }

  .logo2 {
    bottom: 38px;
    right: 38px;
  }

  .lowImg {
    width: 100%;
  }

  .text {
    height: 24px;
  }
`



const StyledSlider = styled(Slider)`
  width: 100%;


  .slide-item {
    width: 100% !important;

    .img {
      width: calc(50vw - 32px);
    }

    .arrow {
      width: 12px;
    }
  }
`

const TotalPage = 15

export default function Page () {
  const pageScrollItem = useOnePageScroll()

  const options = {
    className: "slider variable-width",
    arrows:false,
    speed: 200,
    infinite: true,
    cssEase:'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    draggable: false,
  };

  const ref = useRef()

  return (
    <Container className='flex-col align-center' {...pageScrollItem}>
      <PageTitle
        nav="임플란트"
        sub="임플란트 부작용 치료"
        background={implant_background}
        title={"임플란트\n부작용 치료"}
        subTitle="Dental Implant Complications Treatment"
        isMobile={true}

      />


      <div className='innerSection w-full' id="anker">
        <div className='section'>
          <div className='w-full'>
            <div className='MaruBuri font-12 c-brown02 mb-8'>Dental Implant Complications Treatment</div>
            <div className='mb-20 MaruBuri font-24 line-32 semibold'>Dental Implant Complications Treatment</div>

            <div className='c-black02 font-14 line-22 content keep-all mb-40'>
              치료 받은 임플란트의 나사 주변의 잇몸뼈가 녹는 임플란트 주위염이 생겼을때, 또는 뼈이식재 부작용으로 여러 불편한 증상들이 생겼을때 적절한 치료가 필요합니다. 간단히 치주소파술로 염증을
              제거하거나, 잇몸뼈 수정이 필요한 경우 치주수술을 하기도 하며, 심하게 잇몸뼈가 녹은 경우에는 임플란트를 제거하고 다시 심기도 합니다.
            </div>
          </div>
          
          <GridBox col={2} gap={10}>
            <img className='w-full' src={implant_complications_section1_1}/>
            <img className='w-full' src={implant_complications_section1_2}/>
          </GridBox>
        </div>


        <div className='section'>
          <div className='w-full mb-48'>
            <div className='MaruBuri font-12 c-brown02 mb-8'>Dental Implant Complications Treatment</div>
            <div className='mb-20 MaruBuri font-24 line-32 semibold'>보이는 증상만이 아닌, 불편감의 근본적인 원인을 해소합니다.</div>

            <div className='c-black02 font-14 line-22 content keep-all mb-40'>
            8년 전 모 대학 병원에서 어금니 임플란트 2개를 치료 받았는데 ‘불편감 때문에 하루도 편한 날이 없었다’고 하소연하시는 50대 여성 환자 분이십니다. 불편감의 원인은 꽉막힌 임플란트 보철로, 칫솔질이
            안 되어 염증이 발생했던 것이었습니다. 이전 치과에서 임플란트를 더 깊게 심었거나, 혹은 한 개만 심었더라도 이런 문제는 발생하지 않았을 것입니다. 그래서 기쁜마음치과에서는 임플란트 사이의 칫솔질이
            가능하도록 임플란트 크라운 사이를 3~4mm 정도 개방하는 시술을 하였는데, 환자분은 무척이나 편안해졌다고 말씀해주셨습니다. 이 정도 치아 사이가 벌어져 있는 것은 음식물도 끼지 않으며 기능적으로
            전혀 문제가 되지 않습니다.
            </div>
          </div>
          
          <img className='lowImg' src={implant_complications_m_section2}/>
        </div>


        <div className='section'>
          <div className='w-full mb-48'>
            <div className='MaruBuri font-12 c-brown02 mb-8'>Process</div>
            <div className='mb-20 MaruBuri font-24 line-32 semibold'>치료과정</div>
          </div>
          
          <img className='lowImg'  src={implant_complications_m_section3}/>
        </div>

        <div className='w-full relative'>
          <div className='MaruBuri font-12 c-brown02 mb-8'>Treatment Case</div>
          <div className='flex align-center mb-20 '>
            <div className='MaruBuri font-24 line-32 semibold'>상세 치료사례</div>
            <div className='flex-1'/>
            <div className='flex align-center btnBox'>
              <img src={slide_prev_primary} className='mr-16 pointer' onClick={() => ref?.current?.slickPrev()}/>
              <img src={slide_next_primary} className='pointer' onClick={() => ref?.current?.slickNext()}/>
            </div>
          </div>

          <div className='slideCover'>
            <StyledSlider ref={ref} {...options}>
              {
                [...new Array(Math.ceil(TotalPage/2))].map((post, key) => (
                  <div className='slide-item' key={`list2-post-key-${key}`}>
                    <div className='flex-center'>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/complications/before${key * 2 + 1}.png`}/>
                      <img className='mx-6 arrow' src={slide_big_next}/>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/complications/after${key * 2 + 1}.png`}/>
                    </div>

                    {LabelList[key * 2] !== '' && <div className='flex-center'>
                      <div className='w-full label py-6 text-center c-white font-12 keep-all'>{LabelList[key * 2]}</div>
                    </div>}

                    <div className='mb-10'/>
                    
                    {(key * 2 + 2) <= TotalPage && <>
                      <div className='flex-center'>
                        {
                          key === 6 ? <div className='img mr-40'/> : 
                          <>
                            <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/complications/before${key * 2 + 2}.png`}/>
                            <img className='mx-6 arrow' src={slide_big_next}/>
                          </>
                        }
                        <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/complications/after${key * 2 + 2}.png`}/>
                      </div>

                      {LabelList[key * 2 + 1] !== '' && <div className='flex-center mb-10'>
                        <div className='w-full label py-6 text-center c-white font-12 keep-all'>{LabelList[key * 2 + 1]}</div>
                      </div>}
                    </>}
                  </div>
                ))
              }
            </StyledSlider>
          </div>
        </div>
      </div>
    </Container>
  )
}


const LabelList = [
  '',
  '치주 수술(Osseous Surgery)로 임플란트 주위염 치료',
  '',
  '앞니 임플란트 임플란트 주위염 치주소파, 치주수술(Open Flap Surgery) 후 잇몸 이식',
  '',
  '아래 어금니 임플란트 주위염 → 임플란트 크라운 사이를 3~4mm 정도 개방해서 해결',
  '',
  '잘못 심어진 앞니 임플란트 제거 후 자가골 블록뼈 이식 및 임플란트 재수술',
  '오른쪽 위 앞니(송곳니) 임플란트 나사노출 → 잇몸이식 및 크라운 새로 제작',
  '오른쪽 위 앞니(송곳니) 임플란트 나사노출 → 잇몸이식 및 크라운 새로 제작',
  '뼈이식재 및 임플란트 부작용으로 비염 및 통증으로 고생하셨던 환자 → 임플란트 재수술',
  '‘골유착 실패’ 임플란트 → 상악동 거상 및 자가골 이식으로 임플란트 재수술',
  '',
  '임플란트 주위염의 원인이 된 나사 형태를 바꾸어줌으로써 임플란트 주위염 치료',
  '뼈이식재 부작용으로 4년 이상 고통스러워하시던 환자분 → 뼈이식재 및 부골 제거 수술 후 편해지심 '
]