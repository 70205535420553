import Section1 from '@/ui/Main/Section1/mobile'
import Section10 from '@/ui/Main/section10/mobile'
import Section2 from '@/ui/Main/Section2/mobile'
import Section3 from '@/ui/Main/Section3/mobile'
import Section4 from '@/ui/Main/Section4/mobile'
import Section5 from '@/ui/Main/Section5/mobile'
import Section6 from '@/ui/Main/Section6/mobile'
import Section7 from '@/ui/Main/Section7/mobile'
import Section8 from '@/ui/Main/Section8/mobile'
import Section9 from '@/ui/Main/Section9/mobile'
import { useEffect, useState } from 'react'
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 60px;
`


export default function Mobile ({ Section2List, Section7List, Section9List }) {
  const [active, setActive] = useState(window.scrollY !== 0)



  function goSection2() {
    let element = document.getElementById('main_section_2')
    let top = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      behavior: 'smooth',
      top
    })
  }


  return (
    <Container className='relative'>
      <Section1/>
      <Section2 Section2List={Section2List}/>
      <Section3/>
      <Section4/>
      <Section5/>
      <Section6/>
      <Section7 Section7List={Section7List}/>
      <Section8/>
      <Section9 Section9List={Section9List}/>
      <Section10/>
    </Container>
  )
}