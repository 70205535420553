import { implant_background, implant_implant_section1, implant_implant_section2, implant_implant_section3_background, implant_implant_section3_item1, implant_implant_section3_item2, implant_implant_section3_item3, implant_implant_section3_logo1, implant_implant_section3_logo2, periodontalsurgery_background, periodontalsurgery_crown_section1, periodontalsurgery_crown_section2, periodontalsurgery_forced_m_section1_1, periodontalsurgery_forced_m_section1_2, periodontalsurgery_forced_m_section2, periodontalsurgery_forced_m_section3, periodontalsurgery_forced_m_section4, periodontalsurgery_forced_section1, periodontalsurgery_forced_section2, periodontalsurgery_forced_section3, periodontalsurgery_gummy_section1, periodontalsurgery_gummy_section2, periodontalsurgery_rapid_section1, periodontalsurgery_rapid_section2, periodontalsurgery_rapid_section3, periodontalsurgery_rapid_section4, slide_big_next, slide_next, slide_prev, slide_prev_primary } from '@/assets'
import { GridBox, PageTitle } from '@/components'
import styled from 'styled-components'
import useOnePageScroll from '@/hooks/useOnePageScroll';


const Container = styled.div`
  padding-top: 60px;
  
  .innerSection {
    padding: 60px 20px;

    
    .slideCover {
      width: calc(100%);
    }
     
    .btnBox {
      top: 50px;
      right: 0;
      
      img {
        width: 20px;
      }
    }
  }

  .section {    
    margin-bottom: 60px;


    .content {
      letter-spacing: -1px;
    }
   
  }



  .logo1 {
    top: 32px;
    left: 32px;
  }

  .logo2 {
    bottom: 38px;
    right: 38px;
  }

  .lowImg {
    width: 100%;
  }

  .text {
    height: 24px;
  }
`




export default function Page () {
  const pageScrollItem = useOnePageScroll()


  return (
    <Container className='flex-col align-center' {...pageScrollItem}>
      <PageTitle
        nav="치주수술"
        sub="매복치 교정 수술"
        background={periodontalsurgery_background}
        title="매복치 교정 수술"
        subTitle="Forced Eruption"
        isMobile={true}
      />


      <div className='innerSection w-full' id="anker">
        <div className='section'>
          <div className='w-full'>
            <div className='MaruBuri font-12 c-brown02 mb-8'>Forced Eruption</div>
            <div className='mb-20 MaruBuri font-24 line-32 semibold'>매복치 교정 수술</div>

            <div className='c-black02 font-14 line-22 content keep-all mb-40'>
              매복치 교정 수술은 잇몸을 열어 매복치 크라운 주변 잇몸뼈를 제거하고, 잇몸 속에 있는 치아인 매복치가 밖으로 나올 수 있도록 통로를 만들고 교정 장치를 붙일 수 있게 도와주는 수술입니다.
              사진을 보시면 주황색 네모로 표시한 부분이 바로 왼쪽 위의 송곳니, 바로 매복치가 묻혀있는 곳입니다. 맹출 시기가 지나도 치아가 나오지 못하고 턱 뼈에 묻혀있으면 교정력을 이용해
              매복치가 나올 수 있도록 도와주는 수술이 필요합니다.
            </div>
          </div>
          
          <GridBox col={2} gap={24}>
            <img className='w-full' src={periodontalsurgery_forced_m_section1_1}/>
            <img className='w-full' src={periodontalsurgery_forced_m_section1_2}/>
          </GridBox>

          <div className='medium font-10 c-black03 mt-20'>▶ ︎CT 상에서도 마찬가지로 치아가 묻혀있는 것을 확인하실 수 있습니다.</div>
        </div>




        <div className='section'>
          <div className='w-full mb-48'>
            <div className='MaruBuri font-12 c-brown02 mb-8'>Process</div>
            <div className='mb-20 MaruBuri font-24 line-32 semibold'>치료과정</div>
          </div>
          
          <img className='w-full' src={periodontalsurgery_forced_m_section2}/>
        </div>



        <div className=''>
          <div className='w-full mb-20'>
            <div className='MaruBuri font-12 c-brown02 mb-8'>Treatment Case</div>
            <div className='mb-20 MaruBuri font-24 line-32 semibold'>상세 치료사례</div>

            <div className='c-black02 font-14 line-22 content keep-all'>
            16세 여성 환자의 치료 과정을 담은 사진입니다. 매복된 위 앞니를 치주수술로 노출시켜 교정치료가 가능하도록 하였습니다. 저희 치과에 내원하기 이전, 한 대학 병원의 치주과에서 매복치 수술을
받았었는데, 너무나 아파 많은 고생을 했다고 합니다. 수술 이후, 환자분의 어머니께서는 “별로 안 부었네, 전에는 풍선처럼 부었었는데”라고 말씀하셨을 정도로 환자분은 웃으며 집에 돌아가셨습니다. 
환자분께서 수술을 받는 동안에 갖는 편안함, 그리고 수술 이후의 불편감을 덜 느끼게 되는 것의 기반은 기쁜마음치과만의 ‘격이 다른 실력’입니다.
            </div>
          </div>
          
          <img className='w-full' src={periodontalsurgery_forced_m_section3}/>

          <div className='c-black02 mt-48 mb-20 font-14 line-22 content keep-all'>
            20대 후반 여성의 매복 송곳니 치료사례입니다. 수술 후 부착한 후크에 ​​​교정력을 작용하여 ​1년 후에는 사진에서처럼 송곳니가 밖으로 나오게 됩니다.​
          </div>

          <img className='w-full' src={periodontalsurgery_forced_m_section4}/>
        </div>
        
      </div>
    </Container>
  )
}