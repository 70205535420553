import {
  implant_background,
  implant_implant_section1,
  implant_implant_section2,
  implant_implant_section3_background,
  implant_implant_section3_item1,
  implant_implant_section3_item2,
  implant_implant_section3_item3,
  implant_implant_section3_logo1,
  implant_implant_section3_logo2,
  periodontalsurgery_background,
  periodontalsurgery_gummy_section1,
  periodontalsurgery_gummy_section2,
  periodontalsurgery_root_m_section2,
  periodontalsurgery_root_m_section3,
  periodontalsurgery_root_section1,
  periodontalsurgery_root_section2,
  periodontalsurgery_root_section3,
  periodontalsurgery_root_section3_sub,
  slide_big_next,
  slide_next,
  slide_next_primary,
  slide_prev,
  slide_prev_primary,
} from "@/assets";
import { GridBox, PageTitle } from "@/components";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from "react";
import useOnePageScroll from "@/hooks/useOnePageScroll";

const Container = styled.div`
  padding-top: 60px;

  .innerSection {
    padding: 60px 20px;

    .slideCover {
      width: calc(100%);
    }

    .btnBox {
      top: 50px;
      right: 0;

      img {
        width: 20px;
      }
    }
  }

  .section {
    margin-bottom: 60px;

    .content {
      letter-spacing: -1px;
    }
  }

  .logo1 {
    top: 32px;
    left: 32px;
  }

  .logo2 {
    bottom: 38px;
    right: 38px;
  }

  .lowImg {
    width: 100%;
  }

  .text {
    height: 24px;
  }
`;

const StyledSlider = styled(Slider)`
  width: 100%;

  .slide-item {
    width: 100% !important;

    .img {
      width: calc(50vw - 32px);
    }

    .arrow {
      width: 12px;
    }
  }
`;

const TotalPage = 30;

export default function Mobile() {
  const pageScrollItem = useOnePageScroll();

  const options = {
    className: "slider variable-width",
    arrows: false,
    speed: 200,
    infinite: true,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    draggable: false,
  };

  const ref = useRef();

  return (
    <Container className="flex-col align-center" {...pageScrollItem}>
      <PageTitle
        nav="치주수술"
        sub="잇몸 이식"
        background={periodontalsurgery_background}
        title="잇몸 이식"
        subTitle="Root Coverage"
        isMobile={true}
      />

      <div className="innerSection w-full" id="anker">
        <div className="section">
          <div className="w-full">
            <div className="MaruBuri font-12 c-brown02 mb-8">Root Coverage</div>
            <div className="mb-20 MaruBuri font-24 line-32 semibold">
              잇몸 이식
            </div>

            <div className="c-black02 font-14 line-22 content">
              연조직 이식이라고도 불리는 잇몸 이식은 잘못된 칫솔질, 교정치료,
              외상 등의 원인으로 ​발생한 잇몸 퇴축(잇몸이 내려가 치아 뿌리가
              노출되는 것)을 해소시켜줄 수 있는 술식입니다.
              <br />
              <br />
              잇몸 퇴축은 ​치아 시림과 심미성 저하를 유발하며 단단한 각화된 잇몸
              조직(치은) 결핍으로 인해 치주질환을 가속화하여 치아 수명이
              짧아지게 합니다.
              <br />
              <br />
              교정치료 기간동안 잇몸 퇴축이 발생할 것으로 예상되는 경우에 미리
              잇몸 이식을 하기도 합니다.
              <br />
              <br />
              잇몸 이식은 각화된 입천장의 조직을 사용하는데, 상피조직을 사용하는
              Free Gingival Grafting (FGG) 술식과 상피 하방의 결합조직을
              사용하는 Connective Tissue Grafting (CTG) 술식이 있습니다.
            </div>
          </div>

          <img
            src={periodontalsurgery_root_section1}
            className="w-full mt-40"
          />
        </div>

        <div className="section">
          <div className="w-full mb-48">
            <div className="MaruBuri font-12 c-brown02 mb-8">
              Free Gingival Graft
            </div>
            <div className="mb-20 MaruBuri font-24 line-32 semibold">
              FGG 술식이란 무엇인가요?
            </div>

            <div className="c-black02 line-22 font-14 content keep-all">
              FGG(Free Gingival Grafting)는 입천장 상피(표면 조직)를 포함해서
              채취한 조직을 이식하는 술식입니다.
            </div>
          </div>

          <img className="w-full" src={periodontalsurgery_root_m_section2} />
        </div>

        <div className="section">
          <div className="w-full mb-48">
            <div className="MaruBuri font-12 c-brown02 mb-8">
              Connective Tissue Graft
            </div>
            <div className="mb-20 MaruBuri font-24 line-32 semibold">
              CTG 술식이란 무엇인가요?
            </div>

            <div className="c-black02 line-22 font-14 content keep-all">
              CTG(Connective Tissue Grafting)는 입천장에서 상피 하방의
              결합조직을 필요한 만큼 채취해서 이식할 부위의 잇몸을 들춘 후, 그
              속에 채취한 이식편을 집어넣고 봉합하는 술식입니다. FGG와 CTG 이
              외에도 환자분 케이스에 따라 여러 테크닉들을 변환해 시술하기도
              합니다.
            </div>
          </div>

          <img className="w-full" src={periodontalsurgery_root_m_section3} />

          <div className="mt-20">
            <img
              className="w-full"
              src={periodontalsurgery_root_section3_sub}
            />
            <div className="mt-10">
              <div className="font-16">치료 후 안내사항</div>
              <div className="c-black02 mt-8 line-20 keep-all let-1 font-14">
                이식 수술후, 불편감 감소 및 지혈에 도움이 되도록 입천장 보호
                장치 (Flipper)를 제공해 드립니다. ​이식한 연조직이 10년 이상
                안정적으로 유지되기 위해서는 정확한 칫솔질과 정기점검이
                필요합니다. 잇몸 이식 결과 (얼마나 노출된 치아 뿌리를 덮을수
                있는지)는 시술자의 실력 외에도 환자 분의 주변 잇몸뼈와 치주조직
                상태에 따라 달라집니다. 잇몸 퇴축이 심할수록, 그리고 인접 치조골
                소실이 심할수록 잇몸이식으로 씌을 수 있는 높이가 줄어들 수
                있습니다. (치주질환이 심한 환자의 경우, 잇몸 이식을 진행하더라도
                노출된 뿌리의 최대 50% 높이까지만 감쌀 수 있습니다.)
              </div>
            </div>
          </div>
        </div>

        <div className="w-full relative">
          <div className="MaruBuri font-12 c-brown02 mb-8">Treatment Case</div>
          <div className="flex align-center mb-20 ">
            <div className="MaruBuri font-24 line-32 semibold">
              상세 치료사례
            </div>
            <div className="flex-1" />
            <div className="flex align-center btnBox">
              <img
                src={slide_prev_primary}
                className="mr-16 pointer"
                onClick={() => ref?.current?.slickPrev()}
              />
              <img
                src={slide_next_primary}
                className="pointer"
                onClick={() => ref?.current?.slickNext()}
              />
            </div>
          </div>

          <div className="slideCover">
            <StyledSlider ref={ref} {...options}>
              {[...new Array(TotalPage / 2)].map((post, key) => (
                <div className="slide-item" key={`list2-post-key-${key}`}>
                  <div className="flex-center">
                    <img
                      className="img"
                      src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontalSurgery/root/before${
                        key * 2 + 1
                      }.png`}
                    />
                    <img className="mx-6 arrow" src={slide_big_next} />
                    <img
                      className="img"
                      src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontalSurgery/root/after${
                        key * 2 + 1
                      }.png`}
                    />
                  </div>

                  {LabelList[key * 2] !== "" && (
                    <div className="flex-center">
                      <div className="w-full label py-6 text-center c-white font-12 keep-all">
                        {LabelList[key * 2]}
                      </div>
                    </div>
                  )}

                  <div className=" mb-10" />

                  <div className="flex-center">
                    <img
                      className="img"
                      src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontalSurgery/root/before${
                        key * 2 + 2
                      }.png`}
                    />
                    <img className="mx-6 arrow" src={slide_big_next} />
                    <img
                      className="img"
                      src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontalSurgery/root/after${
                        key * 2 + 2
                      }.png`}
                    />
                  </div>

                  {LabelList[key * 2 + 1] !== "" && (
                    <div className="flex-center mb-10">
                      <div className="w-full label py-6 text-center c-white font-12 keep-all">
                        {LabelList[key * 2 + 1]}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </StyledSlider>
          </div>
        </div>
      </div>
    </Container>
  );
}

const LabelList = [
  "",
  "",
  "잇몸이식 5주 후",
  "잇몸 이식 3년 후",
  "치근 파절로 앞니 발치 및 보철물 제거 후 잇몸 이식 & 지르코니아 크라운으로 브릿지",
  "",
  "",
  "",
  "잇몸이식 1년 후",
  "잇몸이식 2개월 후",
  "교정 치료 시작 전 잇몸이식",
  "임플란트 잇몸이식",
  "임플란트 잇몸이식",
  "잇몸 이식 13개월 후",
  "",
  "잇몸이식 1년 후",
  "잇몸이식 6주 후",
  "잇몸이식 8개월",
  "틀니 임플란트 잇몸이식 6주 후",
  "잇몸이식 5주 후",
  "잇몸이식 2개월 후",
  "잇몸이식 4주 후",
  "잇몸이식 3개월 후",
  "",
  "",
  "잇몸이식 3년 후",
  "",
  "",
  "",
];
