import { implant_background, implant_implant_section1, implant_implant_section2, implant_implant_section3_background, implant_implant_section3_item1, implant_implant_section3_item2, implant_implant_section3_item3, implant_implant_section3_logo1, implant_implant_section3_logo2, periodontaldiseasetreatment_section1, periodontaldiseasetreatment_section2, periodontalsurgery_background, periodontalsurgery_crown_section1, periodontalsurgery_crown_section2, periodontalsurgery_gummy_section1, periodontalsurgery_gummy_section2, slide_big_next, slide_next, slide_next_primary, slide_prev, slide_prev_primary } from '@/assets'
import { GridBox, PageTitle } from '@/components'
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from 'react';
import useOnePageScroll from '@/hooks/useOnePageScroll';



const Container = styled.div`
  padding-top: 80px;
  
  .innerSection {
    padding: 160px 0;
    width: 1180px;

    
    .slideCover {
      width: calc(100%);
    }
     
    .btnBox {
      top: 50px;
      right: 0;
    }
  }

  .section {
    margin-bottom: 160px;

    .content {
      letter-spacing: -1px;
    }
   
  }



  .logo1 {
    top: 32px;
    left: 32px;
  }

  .logo2 {
    bottom: 38px;
    right: 38px;
  }

`



const StyledSlider = styled(Slider)`
  .slide-item {
    .img {
      width: 570px;
      height: 367px;
    }

    .arrow {
      width: 26px;
    }
  }
`

const TotalPage = 11

export default function Page () {
  const pageScrollItem = useOnePageScroll()

  const options = {
    className: "slider variable-width",
    arrows:false,
    speed: 200,
    infinite: true,
    cssEase:'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    draggable: false,
  };

  const ref = useRef()

  return (
    <Container className='flex-col align-center' {...pageScrollItem}>
      <PageTitle
        nav="치주질환 치료"
        sub="치주질환 치료"
        background={implant_background}
        title="치주질환 치료"
        subTitle="Periodontal Disease"
      />


      <div className='innerSection' id="anker">
        <div className='section'>
          <div className='mb-48 w-full'>
            <div className='MaruBuri font-22 c-brown02 mb-12'>Periodontal Disease</div>
            <div className='mb-18 MaruBuri font-42 semibold'>치주질환의 정의</div>

            <div className='c-black02 font-20 line-32 content keep-all'>
            치주 질환이란, 원인균 감염에 의해 치주조직에 발생하는 염증 질환으로 크게 치은염과 치주염으로 나누어집니다. ​먼저 치은염은 치조골(잇몸뼈) 상방의 잇몸(또는 치은)에서 염증이 일어난 상태를 
말하며, 플라그(드물게는 알러지, 외상 등)에 의해 발생합니다. 원인이 제거되면 다시 정상 상태로 돌아올 수 있는 가역적인 질환이지만, 장시간 치은염이 존재하면 치주염으로 진행됩니다.
반면, 풍치라고도 불리는 치주염은​ 잇몸 염증 뿐만 아니라 잇몸 하방의 치주조직(치조골, 치주 인대 등)에도 파괴가 일어난 상태로, 원인 제거가 되어도 파괴된 조직들이 원상복구되지 않습니다.
치주염은 '만성’ 염증이기 때문에 면역이 많이 떨어진 경우가 아니면, 불편감이나 자각 증상이 별로 없습니다. 더구나, 여러 개의 뿌리를 가진 어금니의 경우 별로 흔들리지도 않습니다. 피곤하면 가끔
잇몸이 부었다 며칠 있으면 괜찮아지는 듯 하지만, 치료를 받지 않으면 잇몸뼈는 점점 더 빠른 속도로 녹게 되고 결국 치아를 빼야 되는 상태까지 이르게 됩니다.
            </div>
          </div>
          
          <img className='w-full' src={periodontaldiseasetreatment_section1}/>
        </div>


        <div className='section'>
          <div className='w-full mb-48'>
            <div className='MaruBuri font-22 c-brown02 mb-12'>Diagnosis and Treatment of Periodontal Disease</div>
            <div className='mb-18 MaruBuri font-42 semibold'>기쁜마음치과의 치주질환의 진단 및 치료과정</div>

            <div className='c-black02 font-20 line-32 content keep-all'>
            엑스레이와 구강 내 치주 검사를 한 후, 미국 University of Southern California 치과병원 치주과에서와 동일한 프로토콜로 치주질환 치료를 합니다. 저희 기쁜마음치과는 미국 Hu- Friedy사의
치주 및 수술 기구들만을 사용하여 비외과적 치주치료와 치주수술을 시행합니다. 증상이 심하지 않은 경우에는 비외과적 치주치료(잇몸 치료)를 하며, 필요에 따라서는 추가적으로 치주수술도 진행합니다.
            </div>
          </div>
          
          <img className='w-full' src={periodontaldiseasetreatment_section2}/>
        </div>


        <div className='w-full relative'>
          <div className='MaruBuri font-22 c-brown02 mb-12'>Treatment Case</div>
          <div className='mb-48 MaruBuri font-42 semibold'>상세 치료사례</div>

          <div className='flex-center absolute btnBox'>
            <img src={slide_prev_primary} className='mr-30 pointer' onClick={() => ref?.current?.slickPrev()}/>
            <img src={slide_next_primary} className='pointer' onClick={() => ref?.current?.slickNext()}/>
          </div>

          <div className='slideCover'>
            <StyledSlider ref={ref} {...options}>
              {
                [...new Array(Math.ceil(TotalPage/2))].map((post, key) => (
                  <div className='slide-item' key={`list2-post-key-${key}`}>
                    <div className='flex-center mb-28'>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontaldiseasetreatment/before${key * 2 + 1}.png`}/>
                      <img className='mx-6 arrow' src={slide_big_next}/>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontaldiseasetreatment/after${key * 2 + 1}.png`}/>
                    </div>
                    
                    {[2, 4, 6, 8, 9, 10, 11].includes(key * 2 + 1) ? <div className='flex-center mb-28'>
                      <img className='text' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontaldiseasetreatment/text${key * 2 + 1}.png`}/>
                    </div> : <div className='pt-12'/>}


                    {key !== 5 && <div className='flex-center mb-28'>
                      { key !== 7 ? <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontaldiseasetreatment/before${key * 2 + 2}.png`}/> : <div className='img'/>}
                      { key !== 7 ? <img className='mx-6 arrow' src={slide_big_next}/> : <div className='pl-40'/>}
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontaldiseasetreatment/after${key * 2 + 2}.png`}/>
                    </div>}

                    {[2, 4, 6, 8, 9, 10, 11].includes(key * 2 + 2) ? <div className='flex-center mb-28'>
                      <img className='text' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontaldiseasetreatment/text${key * 2 + 2}.png`}/>
                    </div> : <div className='pt-12'/>}
                  </div>
                ))
              }
            </StyledSlider>
          </div>
        </div>
      </div>
    </Container>
  )
}