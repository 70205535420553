import { floating_menu1, header_logo, header_mobile_menu, header_mobile_menu_close, ic_mobile_arrow_down, ic_mobile_arrow_up, m_h_item1, m_h_item2, m_h_item3, m_h_item4, m_h_item5, m_h_item6 } from '@/assets'
import { GridBox } from '@/components'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'


const Container = styled.div`
  width: 100%;
  height: 60px;
  background-color: #fff;
  border-bottom: 1px solid #CCCCCC;
  box-shadow: 0px 3px 10px rgba(138, 116, 102, 0.05);
  position: fixed;
  top: 0;
  left: 0;

  z-index: 99;

  .logo {
    width: 156px;
  }

  .header_menu {
    position: absolute;
    left: 0;
    top: 60px;
    height: calc(100vh - 60px);
    width: 100vw;
    background-color: #fff;
    overflow-y: scroll;
  }

  .gridItem {
    border: .5px solid rgba(0, 0, 0, 0.2);
  }

  .item_blank {
    background: #F5F5F5;
  }
`


export default function Mobile () {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if(isOpen) document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [isOpen])


  return (
    <Container className='flex-center pl-20'>
      <a href='/' onClick={() => setIsOpen(false)}>
        <img className='logo' src={header_logo}/>
      </a>

      <div className='flex-1'/>

      <img src={isOpen ? header_mobile_menu_close : header_mobile_menu} onClick={() => setIsOpen(!isOpen)}/>
    
      {isOpen && 
        <div className='header_menu'>
          <div className='medium px-20 pt-32 pb-20 font-18'>menu</div>

          <GridBox col={3} gap={0}>
            <div className='gridItem py-18 flex-center'>
              <a href='https://m.booking.naver.com/booking/13/bizes/224458/items/3133851' target={'_blank'}>
                <img className='menuImg' src={m_h_item1}/>
              </a>
            </div>

            <div className='gridItem py-18 flex-center'>
              <Link to='/dentist/place' onClick={() => setIsOpen(false)}>
                <img className='menuImg' src={m_h_item2}/>
              </Link>
            </div>

            <div className='gridItem py-18 flex-center'>
              <a href='/#info' onClick={() => {
                let element = document.getElementById('main_info')
                let top = element.getBoundingClientRect().top + window.scrollY - 60;

                window.scrollTo({
                  top,
                  behavior: 'smooth'
                });

                setIsOpen(false)
              }}>
                <img className='menuImg' src={m_h_item3}/>
              </a>
            </div>

            <div className='gridItem py-18 flex-center'>
              <a href='https://blog.naver.com/yeongsgkim' target={'_blank'}>
                <img className='menuImg' src={m_h_item4}/>
              </a>
            </div>

            <div className='gridItem py-18 item_blank flex-center'>
              <img className='menuImg' src={m_h_item5}/>
            </div>

            <div className='gridItem py-18 flex-center'>
              <a href='tel:025340002'>
                <img className='menuImg' src={m_h_item6}/>
              </a>
            </div>
          </GridBox>

          <div className='px-20 pt-40 pb-72'>
            {
              RouteList.map((post, key) => (
                <BigRouter
                  {...post}
                  key={`header_route-${key}`}
                  onClick={() => setIsOpen(false)}
                />

              ))
            }
            <a href='/periodontaldiseasetreatment' className='block medium font-18 mb-40'>치주질환 치료</a>
            <a href='/aestheticprosthodontics' className='block medium font-18'>심미보철</a>
          </div>

        </div>
      }
    </Container>
  )
}

const BigRouterContainer = styled.div`
  z-index: 1;
  a {
    display: block;
    padding-left: 20px;
  }
`

const BigRouter = ({ routeName, name, route, onClick }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <BigRouterContainer className='relative mb-40' onClick={() => setIsOpen(!isOpen)}>
      <div className='flex align-center'>
        <div className={`medium font-18 `}>{routeName}</div>
        
        <div className='flex-1'/>
        
        <img className='' src={isOpen ? ic_mobile_arrow_up : ic_mobile_arrow_down}/>
      </div>

      {
        isOpen && 
        route.map((post, key) => (
          <a onClick={onClick} className='font-16 mt-24 medium' href={post}>{name[key]}</a>
        ))
      }
    </BigRouterContainer>
  )
}


const RouteList = [
  {
    routeName: '치과소개',
    name: ['의료진', '내부시설', '오시는 길'],
    route: ['/dentist/staff', '/dentist/facility', '/dentist/place']
  },
  {
    routeName: '임플란트',
    name: ['임플란트', '자가골 블록뼈 이식・앞니 임플란트', '상악동 거상・비강 거상', '임플란트 부작용 치료'],
    route: ['/implant/implant', '/implant/bone', '/implant/MaxillarySinusFloorElevation', '/implant/complications']
  },
  {
    routeName: '치주수술',
    name: ['심미 치관 연장술・거미 스마일', '잇몸이식', '치관연장술', '급속 교정 수술', '매복치 교정 수술', '잇몸미백'],
    route: ['/periodontalsurgery/gummy', '/periodontalsurgery/root', '/periodontalsurgery/crown', '/periodontalsurgery/rapid', '/periodontalsurgery/forced', '/periodontalsurgery/depigmentation']
  },
]