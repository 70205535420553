import { aestheticprosthodontics_section1, aestheticprosthodontics_section2, implant_background, implant_implant_section1, implant_implant_section2, implant_implant_section3_background, implant_implant_section3_item1, implant_implant_section3_item2, implant_implant_section3_item3, implant_implant_section3_logo1, implant_implant_section3_logo2, periodontaldiseasetreatment_section1, periodontaldiseasetreatment_section2, periodontalsurgery_background, periodontalsurgery_crown_section1, periodontalsurgery_crown_section2, periodontalsurgery_gummy_section1, periodontalsurgery_gummy_section2, slide_big_next, slide_next, slide_next_primary, slide_prev, slide_prev_primary } from '@/assets'
import { GridBox, PageTitle } from '@/components'
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from 'react';
import useOnePageScroll from '@/hooks/useOnePageScroll';



const Container = styled.div`
  padding-top: 80px;
  
  .innerSection {
    padding: 160px 0;
    width: 1180px;

    
    .slideCover {
      width: calc(100%);
    }
     
    .btnBox {
      top: 50px;
      right: 0;
    }
  }

  .section {
    margin-bottom: 160px;

    .content {
      letter-spacing: -1px;
    }
   
  }



  .logo1 {
    top: 32px;
    left: 32px;
  }

  .logo2 {
    bottom: 38px;
    right: 38px;
  }

`



const StyledSlider = styled(Slider)`
  .slide-item {
    .img {
      width: 570px;
      height: 367px;
    }

    .arrow {
      width: 26px;
    }
  }
`

const TotalPage = 20

export default function Page () {
  const pageScrollItem = useOnePageScroll()

  const options = {
    className: "slider variable-width",
    arrows:false,
    speed: 200,
    infinite: true,
    cssEase:'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    draggable: false,
  };

  const ref = useRef()

  return (
    <Container className='flex-col align-center' {...pageScrollItem}>
      <PageTitle
        nav="심미보철"
        sub="심미보철"
        background={periodontalsurgery_background}
        title="심미보철"
        subTitle="Aesthetic Prosthodontics"
      />


      <div className='innerSection' id="anker">
        <div className='section'>
          <div className='mb-48 w-full'>
            <div className='MaruBuri font-22 c-brown02 mb-12'>Aesthetic Prosthodontics</div>
            <div className='mb-18 MaruBuri font-42 semibold'>심미보철 치료란?</div>

            <div className='c-black02 font-20 line-32 content keep-all'>
            앞니는 한 사람의 외모와 인상에 중요한 역할을 합니다. 따라서 충치, 파절, 변색, 심한 교모, 부정교합, 상실 등의 원인으로 앞니 보철을 해야 하는 경우에는 신중을 기해야 합니다. 남아있는 치아를 최대한
            보존하면서 가장 심미적인 결과를 얻기 위해서는 개개인에 제일 적합한 치료 방법을 찾아야 합니다. 앞니는 치아 크기와 모양, 배열 등을 수복하는 것 외에도 치아 주변의 잇몸 상태(잇몸 선의 높이, 잇몸 색,
            잇몸뼈 의 돌출 여부 등)까지 같이 개선하면 치료 효과가 극대화 될 수 있는데, 저희 기쁜 마음치과에서는 이러한 전체적인 치료를 제공해 드립니다.
            </div>
          </div>
          
          <img className='w-full' src={aestheticprosthodontics_section1}/>
        </div>


        <div className='section'>
          <div className='w-full mb-48'>
            <div className='MaruBuri font-22 c-brown02 mb-12'>Process</div>
            <div className='mb-18 MaruBuri font-42 semibold'>치료과정</div>

            <div className='c-black02 font-20 line-32 content keep-all'>
            40대 여성 환자분의 치료 과정입니다. 오른쪽 위 앞니는 잇몸 이식과 임플란트 식립을 하였으며 좌측의 중ㆍ측절치와 우측 측절치에는 치관 연장술을 한 뒤 세라믹 크라운으로 심미성을 높였습니다.
            </div>
          </div>
          
          <img className='w-full' src={aestheticprosthodontics_section2}/>
        </div>


        <div className='w-full relative'>
          <div className='MaruBuri font-22 c-brown02 mb-12'>Treatment Case</div>
          <div className='mb-48 MaruBuri font-42 semibold'>상세 치료사례</div>

          <div className='flex-center absolute btnBox'>
            <img src={slide_prev_primary} className='mr-30 pointer' onClick={() => ref?.current?.slickPrev()}/>
            <img src={slide_next_primary} className='pointer' onClick={() => ref?.current?.slickNext()}/>
          </div>

          <div className='slideCover'>
            <StyledSlider ref={ref} {...options}>
              {
                [...new Array(Math.ceil(TotalPage/2))].map((post, key) => (
                  <div className='slide-item' key={`list2-post-key-${key}`}>
                    <div className='flex-center mb-28'>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/aestheticprosthodontics/before${key * 2 + 1}.png`}/>
                      <img className='mx-6 arrow' src={slide_big_next}/>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/aestheticprosthodontics/after${key * 2 + 1}.png`}/>
                    </div>
                    
                    {[1, 3, 5, 7, 9, 11, 20].includes(key * 2 + 1) ? <div className='pt-12'/> : <div className='flex-center mb-28'>
                      <img className='text' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/aestheticprosthodontics/text${key * 2 + 1}.png`}/>
                    </div>}


                    <div className='flex-center mb-28'>
                      { (key !== 2 && key !== 4 ) ? <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/aestheticprosthodontics/before${key * 2 + 2}.png`}/> : <div className='img'/>}
                      { (key !== 2 && key !== 4 &&  ![5, 7].includes(key) ) ? <img className='mx-6 arrow' src={slide_big_next}/> : <div className='pl-40'/>}
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/aestheticprosthodontics/after${key * 2 + 2}.png`}/>
                    </div>

                    {[1, 3, 5, 7, 9, 11].includes(key * 2 + 2) ? <div className='pt-12'/> : <div className='flex-center mb-28'>
                      <img className='text' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/aestheticprosthodontics/text${key * 2 + 2}.png`}/>
                    </div>}
                    
                  </div>
                ))
              }
            </StyledSlider>
          </div>
        </div>
      </div>
    </Container>
  )
}