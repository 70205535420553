import { footer_logo } from "@/assets";
import styled from "styled-components";

const Container = styled.div`
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: #4a3e37;

  .innerFooter {
    width: 100%;
  }

  .mt-20 {
    color: #81746b;
  }

  .copylight {
    color: #665950;
  }

  .logo {
    width: 160px;
  }
`;

export default function Mobile() {
  return (
    <Container className="flex-col align-center">
      <div className="innerFooter px-20">
        <img className="logo" src={footer_logo} />

        <div className="mt-20 medium font-12">
          <div className="flex align-center mb-8">
            <div className="mr-4">기쁜마음치과</div>
            <div>김영숙</div>
          </div>

          <div className="flex align-center mb-8">
            <div className="mr-12">사업자번호 102-96-06754</div>
            <div className="mr-4">T</div>
            <div>02 534 0002</div>
          </div>

          <div className="mb-8">
            서울 서초구 서초대로 78길 44 나산 스위트 301호
          </div>

          <div className="copylight">© 기쁜마음치과</div>
        </div>
      </div>
    </Container>
  );
}
