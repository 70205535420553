import styled from 'styled-components'
import useMobileCheck from '@/hooks/useMobileCheck'
import { default as Mobile} from './Main/mobile'
import { default as PC } from './Main/pc'
import { main_section2_item_after1, main_section2_item_after10, main_section2_item_after11, main_section2_item_after12, main_section2_item_after13, main_section2_item_after14, main_section2_item_after15, main_section2_item_after2, main_section2_item_after3, main_section2_item_after4, main_section2_item_after5, main_section2_item_after6, main_section2_item_after7, main_section2_item_after8, main_section2_item_after9, main_section2_item_before1, main_section2_item_before10, main_section2_item_before11, main_section2_item_before12, main_section2_item_before13, main_section2_item_before14, main_section2_item_before15, main_section2_item_before2, main_section2_item_before3, main_section2_item_before4, main_section2_item_before5, main_section2_item_before6, main_section2_item_before7, main_section2_item_before8, main_section2_item_before9, main_section7_item1, main_section7_item2, main_section7_item3, main_section7_item4, main_section7_item5, main_section7_item6 } from '@/assets'
import { useEffect } from 'react'

const Container = styled.div`

`

export default function MainPage () {
  const isMobile = useMobileCheck()

  useEffect(() => {
    console.log(window.location.hash)
  
    if(window.location.hash === '#info') {

      const tick = setTimeout(() => {
        let element = document.getElementById('main_info')
        let top = element.getBoundingClientRect().top + window.scrollY - 20;
        
        window.location.hash = ''
        
        window.scrollTo({
          top,
          behavior: 'smooth'
        })
      }, 200)
      return () => clearTimeout(tick);
    }
    
  }, [window.location])

  
  useEffect(() => {
    if(window.location.hash !== '#info') {
      const tick = setTimeout(() => {
        window.scrollTo({
          top: 0
        })
      }, 200)

      return () => {
        clearTimeout(tick)
        document.body.style= `overflow: auto`;
      }
    }
  }, [])

  return (
    <Container>
      {isMobile
        ? 
        <Mobile
          Section2List={Section2List}
          Section7List={Section7List}
          Section9List={Section9List}
        />
        : 
        <PC 
          Section2List={Section2List}
          Section7List={Section7List}
          Section9List={Section9List}
        />
      }
    </Container>
  )
}

const Section2List = [
  {
    before: main_section2_item_before2,
    after: main_section2_item_after2,
    text: "타 치과에서 앞니 임플란트를 할 수 없다는 설명을 들으셨던 환자분은 자가골 블록뼈 이식과 임플란트 식립을 원활하게 진행하였습니다."
  },
  {
    before: main_section2_item_before3,
    after: main_section2_item_after3,
    text: "타치과에서 앞니 임플란트를 할 수 없다는 설명을 들으셨지만, 자가골 블록뼈 이식과 임플란트 수술 후 잘 사용하고 계십니다. 6년 후 모습"
  },
  {
    before: main_section2_item_before4,
    after: main_section2_item_after4,
    text: "모 대학병원에서 어금니 임플란트 치료후 염증으로 계속 불편해하시던 중, 본원에서 크라운 형태 수정해드린 후 잘 사용하고 계십니다."
  },
  {
    before: main_section2_item_before5,
    after: main_section2_item_after5,
    text: "타 치과의원에서 잘못된 뼈이식 수술로 인한 염증이 원인으로, 기쁜마음치과에서 재수술을 진행하여 모든 증상들을 해결했습니다."
  },
  {
    before: main_section2_item_before6,
    after: main_section2_item_after6,
    text: "뼈이식재 부작용으로 4년 이상 합병증으로 고생하셨었는데, 기쁜마음치과에서 뼈이식재 및 부골 제거 수술 후 편해지셨습니다."
  },
  {
    before: main_section2_item_before7,
    after: main_section2_item_after7,
    text: "타 치과 치료 뼈이식재 및 임플란트 부작용으로 고생하셨던 환자 분은, 기쁜마음치과에서 임플란트 재수술 후 편해지셨습니다."
  },
  {
    before: main_section2_item_before8,
    after: main_section2_item_after8,
    text: "타 치과에서 치료받은 ‘골유착 실패’ 임플란트를 기쁜마음치과에서 재수술 받으신 후 잘 사용하고 계십니다. 환자 분은 실패원인을 술로 알고 계셨지만, 술은 무관합니다."
  },
  {
    before: main_section2_item_before1,
    after: main_section2_item_after1,
    text: "타 치과에서 임플란트를 잘못 심어 나사가 비친 모습입니다. 임플란트 제거 후, 자가골 블록뼈 이식 및 임플란트 시술을 진행하였습니다."
  },
  {
    before: main_section2_item_before9,
    after: main_section2_item_after9,
    text: "국내 모 대학병원에서 잇몸이식 실패후 임플란트해야 한다는 환자분은 잇몸이식 재수술 및 충치 치료 후 치아를 빼지않고 잘 사용하고 계십니다."
  },
  {
    before: main_section2_item_before10,
    after: main_section2_item_after10,
    text: "타 치과에서 앞니 임플란트 치료를 받은지 오래된 환자분 입니다. 기쁜마음치과는 잇몸이식 및 크라운 재제작으로 해결해 드렸습니다."
  },
  {
    before: main_section2_item_before11,
    after: main_section2_item_after11,
    text: "타 치과에서 거미스마일 수술 후 재발하여 기쁜마음치과에서 재수술 및 잇몸 미백 받으신 여배우입니다."
  },
  {
    before: main_section2_item_before12,
    after: main_section2_item_after12,
    text: "타치과 치료 임플란트의 노출된 나사 때문에 고민하시던 환자분께 잇몸이식 및 재크라운 치료로 환한 웃음을 되찾으셨습니다."
  },
  {
    before: main_section2_item_before13,
    after: main_section2_item_after13,
    text: "치관연장술과 지르코니아 크라운 재작, 그리고 잇몸이식을 하여 염증 제거 및 길이의 균형까지도 맞추었습니다."
  },
  {
    before: main_section2_item_before14,
    after: main_section2_item_after14,
    text: "타치과 전악보철 치료 후 혀가 씹히고 입모양이 이상해진 환자분께 심미성과 기능성을 갖춘 전악보철 치료를 다시 해드렸습니다."
  },
  {
    before: main_section2_item_before15,
    after: main_section2_item_after15,
    text: "모 대학병원에서 앞니 치주수술후 망가진 앞니 잇몸을 재수술 후 지르코니아 크라운으로 심미성을 회복해드렸습니다."
  }
]

const Section7List = [
  {
    img: main_section7_item1,
    title: "▶︎ clinical implant dentistry and related research",
  },
  {
    img: main_section7_item2,
    title: "▶︎ Journal de Parodontologie et d'Implantologie Orale",
  },
  {
    img: main_section7_item3,
    title: "▶︎ University of Southern California 레지던트 시절 보스턴 AAP컨퍼런스 참석",
  },
  {
    img: main_section7_item4,
    title: "▶︎ University of Southern California 레지던트 졸업식 당시 동료들과 함께",
  },
  {
    img: main_section7_item5,
    title: "▶︎ University of Southern California 치과대학 치주과 디렉터 Dr. 노자리 교수님과 (대만 학회)",
  },
  {
    img: main_section7_item6,
    title: "▶︎ Dr. Niklaus P. Lang, Dr. Jan Lindhe, Dr. Hessam Nowzari",
  },
]

const Section9List = [
  [
    {
      name: "62****",
      content: "“감사합니다. 원장님” 타치과에서 임플란트 한지 1달 만에 임플란트 주위염 발생. 이 추운 겨울에 의사찾아 삼만리하다가 운좋게도 기쁜마음치과에서 치료를 시작했습니다. (1)실력있는 치주과 전문의. 치료는 첫날 말씀대로 잘 진행되었고, 치료 결과에 만족합니다. 임플란트 가격도 더 싸고 관리도 더 잘 해주는데,,, 처음부터 이곳에서 임플란트를 하지 않은 것이 너무 속상합니다.\ (2)양심쟁이 의사 “김영숙원장 잇몸치료 한번”에 어금니통증 없어지고, 지금은 양배추 두꺼운 부분을 턱 아플때까지 씹어도 이상 없습니다. 임플란트 수술한 치과에서 실장아줌마가 기둥세우고 금크라운 50만원에 하라고 했는데,,, 기쁜마음치과와서 돈 벌었습니다. 이런 치과의사는 처음^^ 저도 믿고 치료받을 수 있는 치과가 생겼습니다. 잘하는지, 너무 비싼건 아닌지, 이런저런 생각 안해도 된다는 사실! 앞으로도 잘 부탁해요,,, 원장님^^",
    }
  ],
  [
    {
      name: "익명의 환자분",
      content: "한줄평: 후회없는 치과선택 잇몸이식수술을 하러 갔을 때 어떻게 수술을 하는 지에 대해서 친절하게 알려주셨습니다. 수술소리가 무서워할 때 이어폰을 낄 수 있도록 배려해주셨고, 수술하는 동안 꼼꼼하게 수술해주신 것을 느낄 수 있었습니다. 모르는 것도 질문했을 때에도 귀찮아하지 않고 잘 설명해주십니다. 병원리뷰: 치과의 크기는 넓지는 않지만 좁지도 않습니다. 의사선생님이 한 분이셔서 딱 적당한 크기이며 예약제이기에 대기시간은 짧습니다. 항상 병원은 청결하였고 직원들도 모두 친절합니다. 가격은 입구에 명시되어 있어 믿을만 합니다."
    }
  ],
  [
    {
      name: "lu*******",
      content: "잇몸때문에 심하게 고생할때 우연히 알고 찾아갔어요. 다른 여러 치과에서는 진단도 방안도 찾아주지 못했지만 여기에서는 바로 명쾌하게 해결해주셨네요. 잇몸수술받고 정기적으로 치료도 받고있습니다. 친절하시고 병원도 깨끗하고 좋아요."
    },
    {
      name: "반*******",
      content: "동네 치주과 찾아가서 치료받아봤지만 여기 원장님처럼 잇몸치료 제대로 하시는 곳은 없는것같아요 거리가 멀지만 3개월마다 잇몸치료 열심히 다니고 있어요~ 여자원장님 꼼꼼하게 치료 잘해주시고 통증원인을 잘찾아서 치료해주셔서 믿고 다니고 있어요~"
    }
  ],
  [
    {
      name: "li*****",
      content: "이 치과 저 치과 다니다가 지푸라기라도 잡는 심정으로 카페 추천 받아서 오게 되었는데 정말 기대 이상이였습니다. 다른 치과에서는 해결하지 못했던 문제의 해결책을 바로 제시해주시더군요. 원장님 진료도 진료지만 쓸데없이 비싸거나 요즘 인터넷에서 나오는 사기치는 치과 의사들이랑은 정말 다르신것 같습니다. 본인 하시는 일에 자부심도 굉장하시고 환자 입장에서 생각하십니다. 이런 원장님들이 많아지셨으면 좋겠네요. 번창하세요."
    },
    {
      name: "비***",
      content: "제가 잇몸 때문에 치과 여러군데 더니면서 오랫동안 고생 했는데, 여기서 임플란트 하고 꾸준히 잇몸 치료 받으니 진짜 살 것 같네요. 원장님이 워낙 꼼꼼하시고 실력 있으서셔, 덕분에 새 삶을 살고 있습니다. 치과 때문에 고생하시는 분들은 꼭 한 번 와보세요."
    }
  ],
  [
    {
      name: "두*****",
      content: "잇몸이식수술하려고 찾은 치과인데 꼼꼼하신게 느껴져서 만족스러워요 ^.^ 편도 2시간이어도 잇몸이식수술 이후로 잇몸치료하러 정기적으로 가고 있어요! 의사선생님께서 여성분이시라 더 편하기도 하고 말씀도 조곤조곤 하시고 치과소리 무서워하니 이어폰으로 노래 들어도 된다고 배려해주셔서 너무 좋았어요~!"
    },
    {
      name: "jo*****",
      content: "근처 다른 치과 갔는데 별 치료해줄 게 없다며 불편하더라도 그냥 지내라 해 방황하다가 가까이 간판이 보여서 혹시나 하고 들어갔습니다.원장님이 바로 원인을 말씀해 주시고 치료해주셨습니다. 스태프분들도 친절하십니다."
    }
  ],
  [
    {
      name: "깡****",
      content: "치주과전문의쌤이라 잇몸이식술정말잘하시고 잇몸건강하게유지할수있도록 치료 잘해주셔요! 덕분에 6년전 다른곳에서 당장임플란트해야한다고얘기들었었는데 문제없이 잇몸이식잘하고 제치아로 잘유지하고 있답니다:)"
    },
    {
      name: "sh*******",
      content: "송*정 입니다. 예약한 날을 빠짐없이 다닌지 2~3년정도 된거 같네요. 크라운인가.. 어금니 씌우는거 본뜨는과정에서 제가 턱이 제 멋대로라...본뜰때마다 위치다르게 해서TT 수차례 다시 해주셨습니다. 저도 꽤 멀었는데 몇 주를 오라는대로 시키는대로 착실히 갔습니다^^ 임플란트 할때도 어찌나 꼼꼼히 하시던지 기공사님이 여러차례 애쓰신걸로 압니다^ 저번에 이전하신다고 하셔서~ 수도권이면 어디든 따라간다했어용 ㅎ"
    }
  ],

]



