import { useEffect } from 'react';
import { isArray } from 'lodash';

// ref: 클릭해서 이벤트 발생하지 않아야 하는 요소들
const useClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // ref배열 (ref 여러개인 경우)
      const modalRoot = document.getElementById('modal-root');

      if (isArray(ref)) {
        if (modalRoot.contains(event.target)) return;
        const results = ref.map((item) => item && item.current && item.current.contains(event.target));
        if (results.includes(false)) return handler(event);
      } else {
        // 단일 ref
        if (!ref.current || ref.current.contains(event.target)) return;
        handler(event);
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchend', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchend', listener);
    };
  }, [ref, handler]);
};

export default useClickOutside;
