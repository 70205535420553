import { implant_background, implant_bone_m_section2, implant_bone_m_section3, implant_bone_section1, implant_bone_section2, implant_bone_section3, implant_implant_section1, implant_implant_section2, implant_implant_section3_background, implant_implant_section3_item1, implant_implant_section3_item2, implant_implant_section3_item3, implant_implant_section3_logo1, implant_implant_section3_logo2, slide_big_next, slide_next, slide_next_primary, slide_prev, slide_prev_primary } from '@/assets'
import { GridBox, PageTitle } from '@/components'
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from 'react';
import useOnePageScroll from '@/hooks/useOnePageScroll';



const Container = styled.div`
  padding-top: 60px;
  
  .innerSection {
    padding: 60px 20px;

    
    .slideCover {
      width: calc(100%);
    }
     
    .btnBox {
      top: 50px;
      right: 0;
      
      img {
        width: 20px;
      }
    }
  }

  .section {    
    margin-bottom: 60px;


    .content {
      letter-spacing: -1px;
    }
   
  }



  .logo1 {
    top: 32px;
    left: 32px;
  }

  .logo2 {
    bottom: 38px;
    right: 38px;
  }

  .lowImg {
    width: 100%;
  }

  .text {
    height: 24px;
  }
`



const StyledSlider = styled(Slider)`
  width: 100%;


  .slide-item {
    width: 100% !important;

    .img {
      width: calc(50vw - 32px);
    }

    .arrow {
      width: 12px;
    }
  }
`

const TotalPage = 12

export default function Page () {

  const options = {
    className: "slider variable-width",
    arrows:false,
    speed: 200,
    infinite: true,
    cssEase:'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    draggable: false,
  };

  const ref = useRef()

  const pageScrollItem = useOnePageScroll()

  return (
    <Container className='flex-col align-center' {...pageScrollItem}>
      <PageTitle
        nav="임플란트"
        sub={`자가골 블록뼈 이식・앞니 임플란트`}
        background={implant_background}
        title={`자가골 블록뼈\n이식・앞니 임플란트`}
        subTitle={`Autogenous Block Bone Grafting・\nanterior implant`}
        isMobile={true}

      />


      <div className='innerSection w-full' id="anker">
        <div className='section'>
          <div className='w-full'>
            <div className='MaruBuri font-12 c-brown02 mb-8'>Autogenous Block Bone Grafting</div>
            <div className='mb-20 MaruBuri font-24 line-32 semibold'>대학병원도 하지 못하는<br/>자가골 이식 전문 치과</div>

            <div className='c-black02 font-14 line-22 content keep-all mb-40'>
              저희 기쁜마음치과는 우리나라에서 대학병원을 포함하여 몇 안 되는 자가골 이식 전문병원입니다.
              뼈 수술이 빈번한 정형외과에서도, 자가골을 뼈이식에 가장 좋은 Gold Standard로 인정합니다.<br/><br/>

              대부분의 치과 임플란트에 사용되는 뼈이식재의 원재료는 소나 돼지 등의 동물뼈, 아니면 품질이
              매우 좋지 못한 합성골입니다.<br/><br/>

              이러한 사실을 알고 계신 분들은 많지 않을 것이며, 자가골이 아닌 다른 뼈이식재를 사용하면
              임플란트 주위염, 비염, 세균 및 바이러스 감염, 면역 반응 등의 부작용 발생 위험이 있습니다.<br/><br/>

              반면, 환자분의 뼈를 사용하는 자가골 이식은 이러한 부작용이 전혀 없는 안전한 뼈이식
              방법이면서도 수술 후 감염이나 면역반응이 일어나지 않기 때문에 수술 후 통증,
              부종 등의 불편감도 가장 적습니다.
            </div>
          </div>
          
          <img className='lowImg'  src={implant_bone_section1}/>
        </div>


        <div className='section'>
          <div className='w-full mb-48'>
            <div className='MaruBuri font-12 c-brown02 mb-8'>Autogenous Block Bone</div>
            <div className='mb-20 MaruBuri font-24 line-32 semibold'>자가골이란?</div>

            <div className='c-black02 font-14 line-22 content keep-all mb-40'>
              아주 단단하지만 살아있는 조직인 자가골에는 뼈를 만드는 세포인 조골세포, ​미세 혈관, BMPs(Bone Morphogenic Proteins) 같은 '성장인자(Growth Factors)' 등이 고농도로 들어 있습니다. 자가골<br/>
              블록뼈를 이식할 경우, ​이식 부위에 있는 혈관이 이식편에 있는 하버스관(Harversian Canals) 과 볼크만관(Volkmann Canals)으로 들어가 연결되며, 4개월 전후로 이식한 자가골과 이식 부위의 뼈가<br/>
              완전히 붙게 되는데, 이를 생착이라고 표현합니다. 즉, 이식한 뼈가 살아서 이식 부위와 ‘하나’가 되는 것입니다.
            </div>
          </div>
          
          <img className='lowImg'  src={implant_bone_m_section2}/>
        </div>


        <div className='section'>
          <div className='w-full mb-48'>
            <div className='MaruBuri font-12 c-brown02 mb-8'>Autogenous Block Bone Grafting</div>
            <div className='mb-20 MaruBuri font-24 line-32 semibold'>자가골 블록뼈 이식</div>

            <div className='c-black02 font-14 line-22 content keep-all mb-40'>
              자가골 블록뼈 이식은 잇몸뼈의 폭을 넓히거나 높이기 위해 필요한 수술입니다. 아래 어금니 뒤쪽의 턱 부분, 또는 턱의 앞 부분에서 필요한 만큼 치밀골을 채취해 이식이 필요한 부위에 나사로 정밀하게<br/>
              고정합니다. 블록뼈를 떼어낸 후 안모 변화는 발생하지 않습니다. 자가골 채취부위 잇몸 상처는 10일~2주 정도면 표면상 완전히 아물며, 채취된 부위의 골조직은 CT 사진에서 보이는 것처럼 2년 정도 지나면<br/>
              원상태로 회복됩니다.
            </div>
          </div>
          
          <img className='lowImg'  src={implant_bone_m_section3}/>
        </div>



        <div className='w-full relative'>
          <div className='MaruBuri font-12 c-brown02 mb-8'>Treatment Case</div>
          <div className='flex align-center mb-20 '>
            <div className='MaruBuri font-24 line-32 semibold'>상세 치료사례</div>
            <div className='flex-1'/>
            <div className='flex align-center btnBox'>
              <img src={slide_prev_primary} className='mr-16 pointer' onClick={() => ref?.current?.slickPrev()}/>
              <img src={slide_next_primary} className='pointer' onClick={() => ref?.current?.slickNext()}/>
            </div>
          </div>

          <div className='slideCover'>
            <StyledSlider ref={ref} {...options}>
              {
                [...new Array(TotalPage/2)].map((post, key) => (
                  <div className='slide-item' key={`list2-post-key-${key}`}>
                    <div className='flex-center'>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/bone/before${key * 2 + 1}.png`}/>
                      <img className='mx-6 arrow' src={slide_big_next}/>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/bone/after${key * 2 + 1}.png`}/>
                    </div>

                    {LabelList[key * 2] !== '' && <div className='flex-center mb-10'>
                      <div className='w-full label py-6 text-center c-white font-12 keep-all'>{LabelList[key * 2]}</div>
                    </div>}
                    
                    <div className='flex-center'>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/bone/before${key * 2 + 2}.png`}/>
                      <img className='mx-6 arrow' src={slide_big_next}/>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/bone/after${key * 2 + 2}.png`}/>
                    </div>

                    {LabelList[key * 2 + 1] !== '' && <div className='flex-center'>
                      <div className='w-full label py-6 text-center c-white font-12 keep-all'>{LabelList[key * 2 + 1]}</div>
                    </div>}
                  </div>
                ))
              }
            </StyledSlider>
          </div>
        </div>
      </div>
    </Container>
  )
}


const LabelList = [
  '앞니 자가골 블록뼈 이식 & 임플란트 치료',
  '앞니 자가골 블록뼈 이식 & 임플란트 치료',
  '좌측 위 송곳니 자가골 블록뼈 이식 & 임플란트 치료',
  '좌측 위 송곳니 자가골 블록뼈 이식 & 임플란트 치료',
  '위 앞니 자가골 블록뼈 이식& 임플란트 치료',
  '위 앞니 자가골 블록뼈 이식& 임플란트 치료',
  '위 앞니 자가골 블록뼈이식 & 임플란트 치료 6년 후',
  '위 앞니 자가골 블록뼈이식 & 임플란트 치료 6년 후',
  '위 좌측 앞니 자가골 블록뼈 이식& 임플란트 치료, 치관 연장술, 올 세라믹 크라운',
  '위 앞니 자가골 블록뼈 이식& 임플란트 치료',
  '아래 앞니 자가골 블록뼈 이식& 임플란트 치료',
  '위 송곳니 자가골 이식 & 임플란트 치료'
]