import { main_section10_background, main_section10_map } from '@/assets'
import { useScrollFadeIn } from '@/hooks'
import styled from 'styled-components'


const Container = styled.div`
  padding: 60px 0;
  
  background-image: url(${main_section10_background});
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  .innerSection {
    width: 100%;
    .title {
      border-bottom: 2px solid #cab08f;
    }
  }

  .section10_map {
    width: calc(100% + 18px);
    margin-left: -9px;
  }

`


export default function Section10 () {

  const animatedItem1 = useScrollFadeIn() 
  const animatedItem2 = useScrollFadeIn() 
  const animatedItem3 = useScrollFadeIn() 
  const animatedItem4 = useScrollFadeIn() 

  return (
    <Container className='flex-col align-center' id="main_info">
      <div className='innerSection px-20 relative'>
        
        <div className='c-brown02 MaruBuri semibold font-12 mb-8' {...animatedItem1}>Information</div>

        
        <div className='font-24 MaruBuri semibold c-white' {...animatedItem2}>기쁜마음치과 진료안내</div>

        
        <div className='font-14 MaruBuri mt-10 mb-40 line-22 c-brown01' {...animatedItem3}>기쁜마음치과에서는 치료가 안 되어서, 혹은<br/>부작용이 생겨서 세 번, 네 번<br/>번거롭게 치과에 방문할 필요가 없으십니다.</div>
      
        <div className='' >
          <div className=''>
            
            <div className='title c-brown01 pb-10 mb-28 font-20 MaruBuri bold'>진료시간</div>
          
            <div className='c-white font-16 mb-4'>평일</div>
            <div className='c-brown01 font-14 mb-20'>오전 9시 30분 ~ 오후 6시 30분</div>
          
            <div className='c-white font-16 mb-4'>토요일</div>
            <div className='c-brown01 font-14 mb-20'>오전 9시 30분 ~ 오후 2시 (점심X)</div>
          
            <div className='c-white font-16 mb-4'>점심시간</div>
            <div className='c-brown01 font-14 mb-20'>오후 1시 ~ 오후 2시</div>
          
            <div className='c-brown02 font-12 line-18'>일요일, 수요일, 공휴일은 휴진입니다. 공휴일 있는 주는<br/>수요일 정상 진료합니다.</div>
          </div>

          <img className='section10_map mt-20' src={main_section10_map}/>

        </div>
      </div>
    </Container>
  )
}