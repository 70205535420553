import { implant_background, implant_implant_section1, implant_implant_section2, implant_implant_section3_background, implant_implant_section3_item1, implant_implant_section3_item2, implant_implant_section3_item3, implant_implant_section3_logo1, implant_implant_section3_logo2, slide_big_next, slide_next, slide_next_primary, slide_prev, slide_prev_primary } from '@/assets'
import { GridBox, PageTitle } from '@/components'
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from 'react';
import useOnePageScroll from '@/hooks/useOnePageScroll';



const Container = styled.div`
  padding-top: 80px;
  
  .innerSection {
    padding: 160px 0;
    width: 1180px;

    
    .slideCover {
      width: calc(100%);
    }
     
    .btnBox {
      top: 50px;
      right: 0;
    }
  }

  .section {
    margin-bottom: 160px;

    .content {
      letter-spacing: -1px;
    }
   
  }



  .logo1 {
    top: 32px;
    left: 32px;
  }

  .logo2 {
    bottom: 38px;
    right: 38px;
  }

  .let {
    letter-spacing: -2px;
  }
`



const StyledSlider = styled(Slider)`
  .slide-item {
    .img {
      width: 570px;
      height: 367px;
    }

    .arrow {
      width: 26px;
    }
  }
`

const TotalPage = 4

export default function Page () {

  const options = {
    className: "slider variable-width",
    arrows:false,
    speed: 200,
    infinite: true,
    cssEase:'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    draggable: false,
  };

  const ref = useRef()

  
  const pageScrollItem = useOnePageScroll()

  return (
    <Container className='flex-col align-center' {...pageScrollItem}>
      <PageTitle
        nav="임플란트"
        sub="임플란트"
        background={implant_background}
        title="임플란트"
        subTitle="Dental Implant Clinic"
      />


      <div className='innerSection' id="anker">
        <div className='section flex-center'>
          <div className='flex-1'>
            <div className='MaruBuri font-22 c-brown02 mb-12'>Implant</div>
            <div className='mb-48 MaruBuri font-42 semibold let'>임플란트 치료는 어떻게 하나요?</div>

            <div className='c-black02 font-20 line-32 content keep-all'>
            기쁜마음치과는 자연치아를 잘 보존하여 치료하는 것을 최우선으로 하고 있습니다. 하지만, 치아를
            이미 상실했거나 치아 보존이 불가능한 경우에는 건강하고 심미적인 임플란트를 오랜 기간 동안 잘
            사용하시는 것을 목표로 임플란트 치료를 진행합니다. <br/><br/>

            최상의 임플란트 치료를 제공하기 위해선, 먼저 환자분 맞춤의 Wax-up Radiographic Stent를
            장착한 상태에서 3D Computed Tomography(CT)를 촬영합니다. 촬영한 CT를 바탕으로,
            임플란트를 식립할 부위의 잇몸뼈 상태(골밀도, 폭경 , 높이 등) 및 주변 신경, 혈관, 상악동, 비강 등
            중요한 해부학적 구조물 등을 모니터 상에서 분석한 후, 컴퓨터 시뮬레이션으로 임플란트 수술을
            계획합니다.
            </div>
          </div>
          
          <img src={implant_implant_section1} style={{marginLeft: '120px', width: '500px'}}/>
        </div>


        <div className='section'>
          <div className='w-full mb-48'>
            <div className='MaruBuri font-22 c-brown02 mb-12'>Process</div>
            <div className='mb-48 MaruBuri font-42 semibold'>치료과정</div>

            <div className='c-black02 font-20 line-32 content keep-all'>
              임플란트 수술은 국소마취 하에서 아래 실제 수술 사진에서처럼 Surgical Stent라는 환자 개별 맞춤 수술유도 장치를 사용함으로써 짧은 시간 내에 최소 오차의 안전하고 정밀한 임플란트 수술이
              이루어집니다. 따라서, 수술 후 부종 및 통증도 적으며(진통제복용은 대개 1회로 끝납니다.) 수술 후 최소 4개월에서 길게는 8개월(잔존 골이 아주 적고 상악동 거상을 한 경우) 정도 임플란트 나사가 잇몸뼈와
              굳는 걸 기다린 후 상부 보철 제작에 들어가며, 보철물이 완성되면 음식물 저작이 가능하게 됩니다.
            </div>
          </div>
          
          <img className='w-full' src={implant_implant_section2}/>
        </div>



        <div className='section'>
          <div className='w-full mb-48'>
            <div className='MaruBuri font-22 c-brown02 mb-12'>Principle</div>
            <div className='mb-48 MaruBuri font-42 semibold'>안전하고도 효과적인 임플란트 치료를 위한 원칙</div>

            <div className='c-black02 font-20 line-32 content keep-all'>
            저희 기쁜마음 치과는 고난이도 임플란트 수술을 안전하고 성공적으로 수행하기 위해 미국 University of Southern California 치과병원 수술 프로토콜을 따릅니다. 
            감염 방지를 위해 가급적 1회용 기구를  사용하며 미국산 Midmark 소독기를 이용 철저한 고압멸균 소독을 기본으로 하고 있습니다. 또한, 안전하고 정밀한 치조골 수술과 
            상악동 거상을 위해서 저희는 ACTEON사의 최신 피에조톰을 보유하고 있으며, 이외에도 미국 Ace Surgical와 Versah 및 스위스의 Geistlich 등 세계적 유수 기업에서 제조한 수술장비와 기구들을 사용합니다.
            </div>
          </div>
          
          <div className='section3_background relative mb-48'>
            <img className='w-full' src={implant_implant_section3_background}/>
            <img className='absolute logo1' src={implant_implant_section3_logo1}/>
            <img className='absolute logo2' src={implant_implant_section3_logo2}/>
          </div>

          <GridBox col={3} gap={30} style={{width: '1180px'}}>
            <img className='w-full' src={implant_implant_section3_item1}/>
            <img className='w-full' src={implant_implant_section3_item2}/>
            <img className='w-full' src={implant_implant_section3_item3}/>
          </GridBox>
        </div>



        <div className='w-full relative'>
          <div className='MaruBuri font-22 c-brown02 mb-12'>Treatment Case</div>
          <div className='mb-48 MaruBuri font-42 semibold'>상세 치료사례</div>

          <div className='flex-center absolute btnBox'>
            <img src={slide_prev_primary} className='mr-30 pointer' onClick={() => ref?.current?.slickPrev()}/>
            <img src={slide_next_primary} className='pointer' onClick={() => ref?.current?.slickNext()}/>
          </div>

          <div className='slideCover'>
            <StyledSlider ref={ref} {...options}>
              {
                [...new Array(TotalPage/2)].map((post, key) => (
                  <div className='slide-item' key={`list2-post-key-${key}`}>
                    <div className='flex-center'>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/implant/before${key * 2 + 1}.png`}/>
                      <img className='mx-6 arrow' src={slide_big_next}/>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/implant/after${key * 2 + 1}.png`}/>
                    </div>
                    
                    <div className='flex-center mt-48'>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/implant/before${key * 2 + 2}.png`}/>
                      <img className='mx-6 arrow' src={slide_big_next}/>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/implant/implant/after${key * 2 + 2}.png`}/>
                    </div>
                  </div>
                ))
              }
            </StyledSlider>
          </div>
        </div>
      </div>
    </Container>
  )
}