import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from 'react';
import { slide_next, slide_next_primary, slide_prev, slide_prev_primary } from '@/assets';
import { useScrollFadeIn } from '@/hooks';



const Container = styled.div`
  padding-bottom: 60px;
  .innerSection {
  }

  .slideCover {
    max-width: calc(100% - 20px);
  }

  .let {
    letter-spacing: -1px;
  }
  
  .slidePrev, .slideNext {
    width: 24px;
    height: 24px;
  }
`

const StyledSlider = styled(Slider)`
  .postTitle {
    display: none;
  }
  .postImgBox {
    transition: .3s;
  }

  /* .slide-item:hover {

    .postImgBox {
      padding: 0;
    }

    .postImg {
      border: 1px solid #B33936;
      filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
    }

    .postTitle { display: block; }
  } */

  .slide-item {
    width: 170px !important;

    img {
      width: 100%;
    }
  }
`



export default function Section7 ({ Section7List }) {
  const options = {
    className: "slider variable-width",
    arrows:false,
    speed: 100,
    infinite: true,
    cssEase:'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
  };


  const slideRef = useRef()

  const animatedItem1 = useScrollFadeIn() 
  const animatedItem2 = useScrollFadeIn() 
  const animatedItem3 = useScrollFadeIn() 
  const animatedItem4 = useScrollFadeIn() 

  const [nextHover, setNextHover] = useState(false)
  const [prevHover, setPrevHover] = useState(false)

  return (
    <Container>
      <div className='innerSection w-full pl-20'>
        <div className='flex-col'>
          <div className='pr-20'>
            <div className='c-brown02 MaruBuri semibold font-12 mb-8' {...animatedItem1}>Value & Principle</div>

            <div className='font-24 MaruBuri semibold' {...animatedItem2}>가치, 그리고 원칙</div>

            <div className='font-14 MaruBuri semibold mt-10 mb-20 line-22 c-primary' {...animatedItem3}>28년이라는 시간 동안 첫째도 실력,<br/>둘째도 실력으로만 왔습니다.</div>

            <div className='font-14 line-20 let' {...animatedItem4}>
              그 결과, 한국인 최초로 세계 3대 치의학 저널인 JPIO를 포함하여 권위
              있는 임플란트 학술지에 논문을 기재하는 영광을 얻을 수 있었습니다.<br/><br/>

              이렇듯 기쁜마음치과의 가치는 “최고의 실력”입니다.<br/><br/>

              저희의 가치를 기대하고 찾아와주신 만큼 <br/>
              대표원장인 제가 직접 진료하는 것을 원칙으로 삼고 있으며, <br/>
              허위 과대광고나 과잉진료 또한 절대로 하지 않고 있습니다.<br/><br/>

              최상의 진료 환경을 위해 각 방으로 분리된 1인 진료실 시스템, <br/>
              그리고 하루 15인 한정의 진료 원칙 역시 변함없이 지키고 있습니다. 
            </div>
        
            <div className='flex align-center justify-end mt-20'>
              <img className='slidePrev mr-20 pointer' onClick={() => slideRef.current.slickPrev()} src={prevHover ? slide_prev_primary : slide_prev_primary} onMouseOver={() => setPrevHover(true)} onMouseLeave={() => setPrevHover(false)}/>
              <img className='slideNext pointer' onClick={() => slideRef.current.slickNext()} src={nextHover ? slide_next_primary : slide_next_primary} onMouseOver={() => setNextHover(true)} onMouseLeave={() => setNextHover(false)}/>
            </div>
          </div>


          <div className='slideCover'>
            <StyledSlider ref={slideRef} {...options}>
              {
                Section7List.map((post, key) => (
                  <div className='slide-item mr-10' key={`slide7-post-key-${key}`}>
                    <div className='pt-20 postImgBox'>
                      <img src={post.img} className='postImg'/>
                    </div>

                    {/* <div className='postTitle keep-all medium font-14 mt-20 c-primary line-24' style={{width: '360px'}}>{post.title}</div> */}
                  </div>
                ))
              }
            </StyledSlider>
          </div>
        </div>

      </div>
    </Container>
  )
}