import { implant_background, implant_implant_section1, implant_implant_section2, implant_implant_section3_background, implant_implant_section3_item1, implant_implant_section3_item2, implant_implant_section3_item3, implant_implant_section3_logo1, implant_implant_section3_logo2, periodontalsurgery_background, periodontalsurgery_crown_section1, periodontalsurgery_crown_section2, periodontalsurgery_gummy_section1, periodontalsurgery_gummy_section2, periodontalsurgery_rapid_section1, periodontalsurgery_rapid_section2, periodontalsurgery_rapid_section3, periodontalsurgery_rapid_section4, slide_big_next, slide_next, slide_prev, slide_prev_primary } from '@/assets'
import { GridBox, PageTitle } from '@/components'
import styled from 'styled-components'

import useOnePageScroll from '@/hooks/useOnePageScroll';



const Container = styled.div`
  padding-top: 80px;
  
  .innerSection {
    padding: 160px 0;
    width: 1180px;

    
    .slideCover {
      width: calc(100%);
    }
     
    .btnBox {
      top: 50px;
      right: 0;
    }
  }

  .section {
    margin-bottom: 160px;

    .content {
      letter-spacing: -1px;
    }
   
  }



  .logo1 {
    top: 32px;
    left: 32px;
  }

  .logo2 {
    bottom: 38px;
    right: 38px;
  }

`




export default function Page () {
  const pageScrollItem = useOnePageScroll()


  return (
    <Container className='flex-col align-center' {...pageScrollItem}>
      <PageTitle
        nav="치주수술"
        sub="급속 교정 수술"
        background={periodontalsurgery_background}
        title="급속 교정 수술"
        subTitle="Rapid Orthodontics"
      />


      <div className='innerSection' id="anker">
        <div className='section flex-center'>
          <div className='flex-1'>
            <div className='MaruBuri font-22 c-brown02 mb-12'>Rapid Orthodontics</div>
            <div className='mb-48 MaruBuri font-42 semibold'>급속 교정 수술</div>

            <div className='c-black02 font-20 line-32 content keep-all'>
              급속 교정수술은 많은 이점을 가지고 있는 술식으로, 일반적인 교정치료보다
              기간이 1/3~1/4로 단축된다는 대표적인 장점이 있습니다. <br/><br/>

              이외에도 교정치료의 부작용인 충치 및 치주질환 발생률이 현저히 줄어들며, 
              치아 뿌리가 짧아지는 현상인 ‘치근흡수’ 또한 거의 발생하지 않습니다. <br/><br/>

              게다가, 교정치료 후의 재발(Relapse)도 현저히 줄어들면서도 교정력에 잘 반응하지 
              않는 송곳니 교정이나 아래 앞니의 함입(Intrusion)도 훨씬 용이하게 됩니다. <br/><br/>

              이처럼 많은 장점들을 가지고 있는 급속 교정 수술은 국소마취로 시술이 가능하며, 수술
              직후에도 출혈이나 통증이 심하지 않아 수술 다음날에도 정상적으로 일상 생활이 가능합니다.
            </div>
          </div>
          
          <img src={periodontalsurgery_rapid_section1} style={{width: '500px', marginLeft: '80px'}}/>
        </div>




        <div className='section'>
          <div className='w-full mb-48'>
            <div className='MaruBuri font-22 c-brown02 mb-12'>Process</div>
            <div className='mb-18 MaruBuri font-42 semibold'>치료과정</div>
          </div>
          
          <img className='w-full' src={periodontalsurgery_rapid_section2}/>
        </div>




        <div className='section'>
          <div className='w-full mb-48'>
            <div className='MaruBuri font-22 c-brown02 mb-12'>Treatment Case 1</div>
            <div className='mb-18 MaruBuri font-42 semibold'>3개월 만에 교정치료를 마친 사례</div>

            <div className='c-black02 font-20 line-32 content keep-all'>
              23세 남성분의 치료사례입니다. 급속 교정수술 및 인비절라인 교정으로 3개월 만에 교정치료를 마쳤습니다.
            </div>
          </div>
          
          <img className='w-full' src={periodontalsurgery_rapid_section3}/>
        </div>



        <div className=''>
          <div className='w-full mb-48'>
            <div className='MaruBuri font-22 c-brown02 mb-12'>Treatment Case 2</div>
            <div className='mb-18 MaruBuri font-42 semibold'>4개월 만에 교정치료를 마친 사례</div>

            <div className='c-black02 font-20 line-32 content keep-all'>
              27세 남성분의 치료사례입니다. 급속 교정수술과 교정기 부착 4개월 만에 아래 앞니 교정치료를 끝마쳤습니다.
            </div>
          </div>
          
          <img className='w-full' src={periodontalsurgery_rapid_section4}/>
        </div>
        
      </div>
    </Container>
  )
}