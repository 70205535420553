import { dentist_facility_background, dentist_facility_list1_1, dentist_facility_list1_2, dentist_facility_list1_3, dentist_facility_list2_1, dentist_facility_list2_2, dentist_facility_list2_3, dentist_facility_list2_4, slide_next, slide_next_primary, slide_prev, slide_prev_primary } from '@/assets'
import { PageTitle } from '@/components'
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from 'react';


const Container = styled.div`
  padding-top: 80px;

  .innerSection {
    padding: 160px 0;
    width: 100%;
    padding-left: 370px;
  }
  
  .slideCover {
    width: calc(100%);
  }
`



const StyledSlider = styled(Slider)`
`

export default function Page () {
  const options = {
    className: "slider variable-width",
    arrows:false,
    speed: 100,
    infinite: false,
    cssEase:'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
  };
  
  const [leftOver1, setLeftOver1] = useState(false)
  const [leftOver2, setLeftOver2] = useState(false)
  
  const [rightOver1, setRightOver1] = useState(false)
  const [rightOver2, setRightOver2] = useState(false)
  
  const slide1ref = useRef()
  const slide2ref = useRef()

  return (
    <Container className='flex-col align-center'>
      <PageTitle
        nav="치과소개"
        sub="내부시설"
        background={dentist_facility_background}
        title="기쁜마음과 환한 웃음을 드리는 공간"
        subTitle="일반병원과는 다른, 기쁜마음치과만의 서비스"
      />


      <div className='innerSection' id="anker">
        <div className='MaruBuri font-22 c-brown02 mb-12'>대기실</div>
        <div className='MaruBuri font-38 semibold'>마음의 안정과 여유를</div>
        
        <div className='flex align-center mt-24 btnBox mb-60'>
          <img src={slide_prev_primary} className='mr-30 pointer' onClick={() => slide1ref?.current?.slickPrev()}/>
          <img src={slide_next_primary} className='pointer' onClick={() => slide1ref?.current?.slickNext()}/>
        </div>

        <div className='slideCover'>
          <StyledSlider {...options} ref={slide1ref}>
            {
              [dentist_facility_list1_1, dentist_facility_list1_2, dentist_facility_list1_3]?.map((post, key) => (
                <div className='slide-item mr-40' key={`list1-post-key-${key}`}>
                  <img src={post}/>
                </div>
              ))
            }
          </StyledSlider>
        </div>

        <div style={{marginTop: '160px'}}/>

        <div className='MaruBuri font-22 c-brown02 mb-12'>진료실</div>
        <div className='MaruBuri font-38 semibold '>모든 환자에게 최고의 진료를</div>
        
        <div className='flex align-center mt-24 btnBox mb-60'>
          <img src={slide_prev_primary} className='mr-30 pointer' onClick={() => slide2ref?.current?.slickPrev()}/>
          <img src={slide_next_primary} className='pointer' onClick={() => slide2ref?.current?.slickNext()}/>
        </div>

        <div className='slideCover'>
          <StyledSlider {...options} ref={slide2ref}>
            {
              [dentist_facility_list2_4, dentist_facility_list2_1, dentist_facility_list2_2, dentist_facility_list2_3]?.map((post, key) => (
                <div className='slide-item mr-40' key={`list2-post-key-${key}`}>
                  <img src={post}/>
                </div>
              ))
            }
          </StyledSlider>
        </div>
      </div>
    </Container>
  )
}