import { pagetitle_arrow, pagetitle_home, pagetitle_scroll } from '@/assets/components'
import styled from 'styled-components'


const Container = styled.div`
  height: ${({isMobile}) => isMobile ? 'calc(100vh - 60px)' : 'calc(100vh - 80px)'};
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  background-image: url(${({background}) => background});

  .scrollBtn {
    bottom: 50px;
  }
`


export default function PageTitle ({
  nav="",
  sub="",
  background,
  title="",
  subTitle,
  isMobile=false,
}) {

    
  function goAnker() {
    let element = document.getElementById('anker')
    let top = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      behavior: 'smooth',
      top
    })
  }

  return (
    <Container className='relative flex-col flex-center' id='page_title_section' background={background} isMobile={isMobile}>
      <div className={`flex-center c-white ${isMobile ? 'font-14' : 'font-16'}`}>
        <img src={pagetitle_home}/>

        <img className='mx-16' src={pagetitle_arrow}/>
        
        <div className='medium'>{nav}</div>

        <img className='mx-16' src={pagetitle_arrow}/>

        <div className='bold'>{sub}</div>
        
      </div>

      <div className={`${isMobile ? 'mt-16 c-white MaruBuri semibold font-36 pre-line line-44 text-center' : 'mt-50 c-white MaruBuri semibold font-60 pre-line'}`}>{title}</div>

      {subTitle && <div className={`${isMobile ? 'MaruBuri mt-20 c-white font-16 pre-line line-22 text-center' : 'MaruBuri mt-18 c-white font-24'}`}>{subTitle}</div>}

      <img className='scrollBtn pointer absolute' src={pagetitle_scroll} onClick={goAnker}/>
    </Container>
  )
}