import { dentist_facility_background, dentist_facility_list1_1, dentist_facility_list1_2, dentist_facility_list1_3, dentist_facility_list2_1, dentist_facility_list2_2, dentist_facility_list2_3, dentist_facility_list2_4, slide_next_primary, slide_prev_primary } from '@/assets'
import { PageTitle } from '@/components'
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useRef } from 'react';


const Container = styled.div`
  padding-top: 60px;

  .innerSection {
    padding: 60px 0;
    width: 100%;
    padding-left: 20px;
  }
  
  .slideCover {
    width: calc(100%);
  }
  
  .arrow {
    width: 20px;
    height: 20px;
  }
`



const StyledSlider = styled(Slider)`
  .slide-item {
    img {
      width: 210px;
      height: 150px;
    }
  }
`

export default function Page () {
  const options = {
    className: "slider variable-width",
    arrows:false,
    speed: 100,
    infinite: false,
    cssEase:'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
  };

  const slide1ref = useRef()
  const slide2ref = useRef()
  
  return (
    <Container className='flex-col align-center' >
      <PageTitle
        nav="치과소개"
        sub="내부시설"
        background={dentist_facility_background}
        title={`기쁜마음과 환한\n웃음을 드리는 공간`}
        subTitle="일반병원과는 다른, 기쁜마음치과만의 서비스"
        isMobile={true}
      />


      <div className='innerSection' id="anker">
        <div className='MaruBuri font-12 c-brown02 mb-10'>대기실</div>
        <div className='MaruBuri font-24 semibold'>마음의 안정과 여유를</div>
        
        <div className='flex align-center justify-end pr-20 mt-12 mb-20 btnBox'>
          <img src={slide_prev_primary} className='mr-20 pointer arrow' onClick={() => slide1ref?.current?.slickPrev()}/>
          <img src={slide_next_primary} className='pointer arrow' onClick={() => slide1ref?.current?.slickNext()}/>
        </div>

        <div className='slideCover'>
          <StyledSlider {...options} ref={slide1ref}>
            {
              [dentist_facility_list1_1, dentist_facility_list1_2, dentist_facility_list1_3]?.map((post, key) => (
                <div className='slide-item mr-10' key={`list1-post-key-${key}`}>
                  <img src={post}/>
                </div>
              ))
            }
          </StyledSlider>
        </div>

        <div style={{marginTop: '60px'}}/>

        <div className='MaruBuri font-12 c-brown02 mb-10'>진료실</div>
        <div className='MaruBuri font-24 semibold '>모든 환자에게 최고의 진료를</div>

        <div className='flex align-center justify-end pr-20 mt-12 mb-20 btnBox'>
          <img src={slide_prev_primary} className='mr-20 pointer arrow' onClick={() => slide2ref?.current?.slickPrev()}/>
          <img src={slide_next_primary} className='pointer arrow' onClick={() => slide2ref?.current?.slickNext()}/>
        </div>

        <div className='slideCover'>
          <StyledSlider {...options} ref={slide2ref}>
            {
              [dentist_facility_list2_4, dentist_facility_list2_1, dentist_facility_list2_2, dentist_facility_list2_3]?.map((post, key) => (
                <div className='slide-item mr-10' key={`list2-post-key-${key}`}>
                  <img src={post}/>
                </div>
              ))
            }
          </StyledSlider>
        </div>
      </div>
    </Container>
  )
}