import { implant_background, implant_implant_section1, implant_implant_section2, implant_implant_section3_background, implant_implant_section3_item1, implant_implant_section3_item2, implant_implant_section3_item3, implant_implant_section3_logo1, implant_implant_section3_logo2, periodontalsurgery_background, periodontalsurgery_depigmentation_section1, periodontalsurgery_depigmentation_section2, periodontalsurgery_gummy_section1, periodontalsurgery_gummy_section2, slide_big_next, slide_next, slide_next_primary, slide_prev, slide_prev_primary } from '@/assets'
import { GridBox, PageTitle } from '@/components'
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from 'react';
import useOnePageScroll from '@/hooks/useOnePageScroll';



const Container = styled.div`
  padding-top: 80px;
  
  .innerSection {
    padding: 160px 0;
    width: 1180px;

    
    .slideCover {
      width: calc(100%);
    }
     
    .btnBox {
      top: 50px;
      right: 0;
    }
  }

  .section {
    margin-bottom: 160px;

    .content {
      letter-spacing: -1px;
    }
   
  }



  .logo1 {
    top: 32px;
    left: 32px;
  }

  .logo2 {
    bottom: 38px;
    right: 38px;
  }

`



const StyledSlider = styled(Slider)`
  .slide-item {
    .img {
      width: 570px;
      height: 367px;
    }

    .arrow {
      width: 26px;
    }
  }
`

const TotalPage = 7

export default function Page () {
  const pageScrollItem = useOnePageScroll()


  const options = {
    className: "slider variable-width",
    arrows:false,
    speed: 200,
    infinite: true,
    cssEase:'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    draggable: false,
  };

  const ref = useRef()

  return (
    <Container className='flex-col align-center' {...pageScrollItem}>
      <PageTitle
        nav="치주수술"
        sub="잇몸미백"
        background={periodontalsurgery_background}
        title="잇몸미백"
        subTitle="Gum Depigmentation"
      />


      <div className='innerSection' id="anker">
        <div className='section flex-center'>
          <div className='flex-1'>
            <div className='MaruBuri font-22 c-brown02 mb-12'>Gum Depigmentation</div>
            <div className='mb-48 MaruBuri font-42 semibold'>잇몸미백</div>

            <div className='c-black02 font-20 line-32 content keep-all'>
            많은 사람들은 하얀 치아를 갖는 것을 원하지만 아직 잇몸 색깔에 대해서는
            아직 관심이 훨씬 덜한 것이 사실입니다. 

            하지만 치아 색이 누렇거나 어두우면 나이 들어 보이고 깔끔한 인상을 줄 수 없는 것처럼,
            잇몸의 색깔 역시 심미적으로도 매우 중요한 역할을 합니다.

            잇몸 미백은 잇몸에 침착되어 있는 멜라닌 색소를 제거해 숨겨져있던 밝은 선홍빛 잇몸을 노출시키는 술식입니다.<br/><br/>

            얼굴이나 다른 부위의 피부에 생기는 주근깨의 원인인 멜라닌 색소가 구강 내 잇몸에도
            과도하게 많아지면 잇몸이 검게 보이게 되는데, 이를 제거하면 이전보다 환한 인상을 줄 수 있습니다.
            </div>
          </div>
          
          <img src={periodontalsurgery_depigmentation_section1} style={{marginLeft: '120px', width: '500px'}}/>
        </div>


        <div className='section'>
          <div className='w-full mb-48'>
            <div className='MaruBuri font-22 c-brown02 mb-12'>Process</div>
            <div className='MaruBuri font-42 semibold'>치료과정</div>
          </div>
          
          <img className='w-full' src={periodontalsurgery_depigmentation_section2}/>
        </div>


        <div className='w-full relative'>
          <div className='MaruBuri font-22 c-brown02 mb-12'>Treatment Case</div>
          <div className='mb-48 MaruBuri font-42 semibold'>상세 치료사례</div>

          <div className='flex-center absolute btnBox'>
            <img src={slide_prev_primary} className='mr-30 pointer' onClick={() => ref?.current?.slickPrev()}/>
            <img src={slide_next_primary} className='pointer' onClick={() => ref?.current?.slickNext()}/>
          </div>

          <div className='slideCover'>
            <StyledSlider ref={ref} {...options}>
              {
                [...new Array(Math.ceil(TotalPage/2))].map((post, key) => (
                  <div className='slide-item' key={`list2-post-key-${key}`}>
                    <div className='flex-center'>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontalSurgery/depigmentation/before${key * 2 + 1}.png`}/>
                      <img className='mx-6 arrow' src={slide_big_next}/>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontalSurgery/depigmentation/after${key * 2 + 1}.png`}/>
                    </div>
                    
                    {key !== 3 && 
                    <div className='flex-center mt-48'>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontalSurgery/depigmentation/before${key * 2 + 2}.png`}/>
                      <img className='mx-6 arrow' src={slide_big_next}/>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontalSurgery/depigmentation/after${key * 2 + 2}.png`}/>
                    </div>}
                  </div>
                ))
              }
            </StyledSlider>
          </div>
        </div>
      </div>
    </Container>
  )
}