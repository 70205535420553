import { main_section3_main } from '@/assets'
import { useScrollFadeIn } from '@/hooks'
import styled from 'styled-components'


const Container = styled.div`
  padding: 100px 0;
`


export default function Section3 () {

  const animatedItem1 = useScrollFadeIn() 
  
  return (
    <Container className='flex-col flex-center w-full'>
      <img src={main_section3_main} {...animatedItem1}/>
    </Container>
  )
}