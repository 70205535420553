import styled from 'styled-components'

import { dentist_staff_background, dentist_staff_doctor, dentist_staff_journal1, dentist_staff_journal2, dentist_staff_journal_btn, dentist_staff_m_journal, dentist_staff_record, dentist_staff_record_btn, dentist_staff_sign } from '@/assets';
import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination } from "swiper";
import { PageTitle } from '@/components';
import useOnePageScroll from '@/hooks/useOnePageScroll';

const Container = styled.div`
  padding-top: 60px;
  .innerSection {
    width: 100%;
    
    .doctor {
      width: 100%;
    }
  }

  .mySwiper {
    overflow: hidden;
    height: 643px;
  }

  .introContainer {
  }

  .infoContainer {

    .dotCircle {
      width: 8px;
      height: 8px;

      background-color: #f1eee5;
      border-radius: 50%;
    }
    
    .link_btn {
      scale: 0.8;
      margin-left: -6px;
      margin-bottom: 24px;
    }
  }

  
`


export default function Page () {
  const pageScrollItem = useOnePageScroll()
  
  return (
    <Container className='flex-col align-center' {...pageScrollItem}>
      <PageTitle
        nav="치과소개"
        sub="의료진"
        background={dentist_staff_background}
        title={`기쁜마음치과의\n믿음직한 의료인`}
        anker={"anker"}
        isMobile={true}
      />


      <div className='innerSection px-20 py-60' id="anker">
        <IntroSection/> 

        <img className='doctor my-60' src={dentist_staff_doctor}/>
        
        <InfoSection/> 
      </div>
    </Container>
  )
}


const IntroSection = () => {
  
  return (
    <div className='introContainer flex-col justify-center'>
      <div className='font-12 semibold MaruBuri c-brown02'>Yeong Sug Kim</div>
      <div className='mt-10 font-24 MaruBuri c-black01 semibold mb-20'>28년 동안 같은 마음으로</div>

      <div className='line-22 let-1 font-14 keep-all' style={{color: '#555'}}>
        안녕하세요, 기쁜마음치과의 대표원장 김영숙입니다.
        저 역시도 아픈 치아 때문에 큰 고생을 했던 경험이 있습니다. 어린 시절, 경제적으로 어려워 
        치과 진료를 제때 받지 못했기 때문이지요. 저 또한 너무나 힘들고 고통스러웠었기에, 환자분께서도 
        얼마나 힘드셨을지 그 마음이 온전히 이해됩니다.<br/><br/>

        저는 이제 치과의사로서의 첫 걸음을 뗀 지 30여년이 넘게 흘렀습니다. 실력있는 치주 전문가가
        되었지만 “아픈 치아로 고생하는 사람이 없는 세상''을 꿈꾸며 치과대학에 입학했던 그 시절의 마음은
        그대로 간직한 채 진료를 하고 있습니다.<br/><br/>

        하늘을 우러러 한 점의 부끄러움 없는 양심적인 진료만을 고집하며 오직 환자에 대한
        의무만을 지키겠습니다. 감사합니다.
      </div>

      <div className='flex align-center relative mt-38'>
        <div className='mr-8'>기쁜마음치과 대표원장</div>
        <div className='font-18 MaruBuri semibold relative'>김영숙</div>
        <img src={dentist_staff_sign} style={{marginLeft: '-18px'}}/>
      </div>
    </div>
  )
}

const InfoSection = () => {
  const [current, setCurrent] = useState(1)
  return (
    <div className='infoContainer flex-col justify-center'>
      <div className='font-12 semibold MaruBuri c-brown02'>Yeong Sug Kim</div>
      <div className='mt-10 font-24 MaruBuri c-black01 semibold mb-24'>대표원장 김영숙</div>

      { current === 0 && 
        <div className='relative'>
          <img className='link_btn pointer' src={dentist_staff_journal_btn} onClick={() => setCurrent(1)}/>
          <img src={dentist_staff_m_journal}/>
        </div>
      }

      {
        current === 1 && 
        <div className='relative'>
          <img className='link_btn pointer' src={dentist_staff_record_btn} onClick={() => setCurrent(0)}/>
          <div className='flex align-center font-16'>
            <div className='dotCircle mr-12'/>
            <div className='line-26'>세계 3대 치주 임플란트 학술지<br/>Journal de parodontologie et<br/>d’implantologie</div>
          </div>
          <div className='pl-20 font-12 mt-4 mb-28'>한국인 치과의사 최초 논문기고</div>

          <div className='flex align-center font-16'>
            <div className='dotCircle mr-12'/>
            <div className='line-26'>미국 Clinical Implant Dentistry and<br/>Related Research</div>
          </div>
          <div className='pl-20 font-12 mt-4 mb-28'>논문기고</div>

          <div className='flex align-center font-16'>
            <div className='dotCircle mr-12'/>
            <div className='line-26'>한국할술지치과계</div>
          </div>
          <div className='pl-20 font-12 mt-4 mb-40'>공식저자</div>
{/* 
          <div className=''>
            <img className='mb-24' src={dentist_staff_journal1}/>
            <img src={dentist_staff_journal2}/>
          </div> */}
        </div>
      }
    </div>
  )
}