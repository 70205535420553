import { main_section4_m_main, main_section4_m_sign } from "@/assets";
import { useScrollFadeIn } from "@/hooks";
import { getTheme } from "@/styles/theme";
import styled from "styled-components";

const Container = styled.div`
  .mainImg {
    width: calc(100% - 40px);
    max-width: 320px;
  }

  .innerContainer {
    padding-top: calc(55vw + 20px);
    margin-top: -55vw;
    padding-bottom: 40px;
    background-color: ${getTheme("primary")};
  }
`;

export default function Section4() {
  const animatedItem1 = useScrollFadeIn();
  return (
    <Container className='flex-center flex-col w-full'>
      <img className='mainImg' src={main_section4_m_main} {...animatedItem1} />
      <div className='w-full innerContainer px-20 keep-all c-white font-14 line-22 let-1'>
        저는 모교에서 하버드 치과대학과 더불어 세계 최고의 치주 및 심화
        임플란트학 프로그램을 제공하는 ‘두개악안면 생물학’을 전공했습니다.
        치과대학 전공 이름이 생물학이라니, 생소하시죠?
        <br />
        <br />
        2000년대 노벨의학상 수상자 총 53명 중 29명이 미국인 의학자일만큼 최고의
        의학 선진국인 미국에서는 치주치료와 임플란트를 단순히 새로운 치아를 심고
        때우는 것으로 여기지 않습니다. 치아와 연관된 다양한 조직과 세포들에 대한
        심오한 이해를 요구하는 과학으로 여기지요.
        <br />
        <br />
        저희 기쁜마음치과에서는 이러한 선진과학 지식과 풍부한 임상경험이 배경이
        되어 진료를 보기에 기존의 국내 병원에서는 치료하기 어려운 질환들을
        부작용 발생이 최소화되도록 치료합니다.
        <img className='mt-8' src={main_section4_m_sign} />
      </div>
    </Container>
  );
}
