import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useScrollFadeIn, useScrollFadeInLTR } from '@/hooks';
import { slide_next, slide_next_primary, slide_prev, slide_prev_primary } from '@/assets';
import { useRef, useState } from 'react';


const Container = styled.div`
  padding: 160px 0;

  .innerSection {
    padding-left: 370px;
    margin-top: 128px;
  }

  .slideCover {
    width: calc(100% - 450px);
  }

  .keep-all {
    letter-spacing: -1.5px;
  }

`

const StyledSlider = styled(Slider)`
  .imgAfter {
    display: none;
  }

  .imageBox:hover {
    .imgAfter { display: block; }
    .imgBefore { display: none; }
  }
`

export default function Section2 ({ Section2List }) {
  const [leftOver1, setLeftOver1] = useState(false)
  const [leftOver2, setLeftOver2] = useState(false)
  const [leftOver3, setLeftOver3] = useState(false)

  const [rightOver1, setRightOver1] = useState(false)
  const [rightOver2, setRightOver2] = useState(false)
  const [rightOver3, setRightOver3] = useState(false)


  const animatedItem1 = useScrollFadeIn() 
  const animatedItem2 = useScrollFadeIn() 
  const animatedItem3 = useScrollFadeIn() 
  const animatedItem4 = useScrollFadeIn() 
  const animatedItem5 = useScrollFadeIn() 
  const animatedItem6 = useScrollFadeIn() 
  const animatedItem7 = useScrollFadeInLTR() 
  const animatedItem8 = useScrollFadeInLTR() 
  const animatedItem9 = useScrollFadeInLTR() 

  
  const options = {
    className: "slider variable-width",
    arrows:false,
    speed: 100,
    infinite: false,
    cssEase:'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
  };

  const slide1ref = useRef()
  const slide2ref = useRef()
  const slide3ref = useRef()

  return (
    <Container className='relative' id="main_section_2">
      
      <div className='text-center MaruBuri semibold c-brown02 font-22 mb-18' {...animatedItem1}>Exceptional</div>

      
      <div className='font-38 MaruBuri semibold mb-28 text-center' {...animatedItem2}>선진과학을 바탕으로 한 풍부한 임상경험</div>
      
      
      <div className='font-18 MaruBuri semibold line-28 c-primary text-center' {...animatedItem3}>
        10명 중 6분은 치주/ 임플란트 수술 부작용과 대학병원 및 대형병원에서<br/> 
        치료하지 못하는 질환들을 호소하는 환자입니다.
      </div>

      <div className='innerSection w-full'>
        <div className='flex' style={{marginBottom: '200px'}}>
          <div {...animatedItem7}>
            <div className='' style={{width: '300px', marginRight: '150px'}}>
              
              <div className='c-brown02 MaruBuri semibold font-22 mb-18'>Implant</div>
              
              <div className='font-36 MaruBuri semibold line-50'>국내에 몇 없는<br/>자가골 이식<br/>임플란트 전문 치과</div>
            </div>

            
            
          </div>

          <div className='slideCover' {...animatedItem4}>
            
            <StyledSlider {...options} ref={slide1ref}>
              {
                Section2List.slice(0, 8).map((post, key) => (
                  <div className='slide-item mr-40' key={`slide3-post-key-${key}`}>
                    <div className='imageBox'>
                      <img src={post.before} className='imgBefore'/>
                      <img src={post.after} className='imgAfter'/>
                    </div>

                    <div className='keep-all font-18 mt-24 c-black02 line-26' style={{width: '420px'}}>{post.text}</div>
                  </div>
                ))
              }
            </StyledSlider>

            <div className='flex-center absolute mt-24 btnBox'>
              <img src={slide_prev_primary} className='mr-30 pointer' onClick={() => slide1ref?.current?.slickPrev()}/>
              <img src={slide_next_primary} className='pointer' onClick={() => slide1ref?.current?.slickNext()}/>
            </div>

          </div>
        </div>

        <div className='flex' style={{marginBottom: '200px'}}>
          
          <div {...animatedItem8}>
            <div className='' style={{width: '300px', marginRight: '150px'}}>
              
              <div className='c-brown02 MaruBuri semibold font-22 mb-18'>periodontal</div>
              
              <div className='font-36 MaruBuri semibold line-50'>치주가 아름다워야<br/>치아도 빛이납니다</div>
            </div>

            
          </div>

          <div className='slideCover' {...animatedItem5}>
            <StyledSlider {...options} ref={slide2ref}>
              {
                Section2List.slice(8, 12).map((post, key) => (
                  <div className='slide-item mr-40' key={`slide3-post-key-${key}`}>
                    <div className='imageBox'>
                      <img src={post.before} className='imgBefore'/>
                      <img src={post.after} className='imgAfter'/>
                    </div>

                    <div className='keep-all font-18 mt-24 c-black02 line-26' style={{width: '420px'}}>{post.text}</div>
                  </div>
                ))
              }
            </StyledSlider>

            <div className='flex-center absolute mt-24 btnBox'>
              <img src={slide_prev_primary} className='mr-30 pointer' onClick={() => slide2ref?.current?.slickPrev()}/>
              <img src={slide_next_primary} className='pointer' onClick={() => slide2ref?.current?.slickNext()}/>
            </div>
          </div>
        </div>

        <div className='flex' style={{marginBottom: '80px'}}>
          <div {...animatedItem9}>
            <div className='' style={{width: '400px', marginRight: '50px'}}>
              
              <div className='c-brown02 MaruBuri semibold font-22 mb-18'>Aesthetic Dentistry</div>
              
              <div className='font-36 MaruBuri semibold line-50'>환자의 밝은 미소를 <br/>되찾기 위한 <br/>기쁜마음치과의 노력</div>
            </div>

          </div>

          <div className='slideCover' {...animatedItem6}>
            <StyledSlider {...options} ref={slide3ref}>
              {
                Section2List.slice(12, 15).map((post, key) => (
                  <div className='slide-item mr-40' key={`slide3-post-key-${key}`}>
                    <div className='imageBox'>
                      <img src={post.before} className='imgBefore'/>
                      <img src={post.after} className='imgAfter'/>
                    </div>

                    <div className='keep-all mt-24 font-18 c-black02 line-26' style={{width: '420px'}}>{post.text}</div>
                  </div>
                ))
              }
            </StyledSlider>

            <div className='flex-center absolute mt-24 btnBox'>
              <img src={slide_prev_primary} className='mr-30 pointer' onClick={() => slide3ref?.current?.slickPrev()}/>
              <img src={slide_next_primary} className='pointer' onClick={() => slide3ref?.current?.slickNext()}/>
            </div>
          </div>
        </div>
      </div>


    </Container>
  )
}