import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useRef } from 'react';
import { useScrollFadeIn } from '@/hooks';

const Container = styled.div`
  padding: 60px 0;
  width: 100%;
  overflow-x: hidden;

  .slideCover {
    width: 100vw;
  }

  .innerSection {
  }

  .slidePrev {
    z-index: 1;
    top: 200px;
    left: -100px;
  }
  .slideNext {
    z-index: 1;
    top: 200px;
    right: -100px;
  }
`


const StyledSlider = styled(Slider)`
  width: 100vw;
  height: 700px !important;
  overflow-y: hidden;

  .slick-list {
    width: 100%;
  }

  .slide-item {
    width: calc(100vw - 80px) !important;
  }

  .post_item {
    transition: .2s;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .post_item:hover {
    border: 1px solid #B33936;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }
`

export default function Section9 ({ Section9List }) {
  const options = {
    className: "slider variable-width",
    arrows:false,
    speed: 300,
    infinite: true,
    cssEase:'linear',
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    slidesToScroll: 1,
    centerMode: true,
    swipeToSlide: true,
    variableWidth: true,
  };


  const slideRef = useRef()

  const animatedItem1 = useScrollFadeIn() 
  const animatedItem2 = useScrollFadeIn() 
  const animatedItem3 = useScrollFadeIn() 

  function sliderClick() {
    console.log(slideRef.current)
    slideRef.current.slickPause()

    setTimeout(() => slideRef.current.slickPlay(), 3000)
  }

  return (
    <Container className='flex-col align-center'>
      <div className='innerSection relative'>

        <div className='c-brown02 MaruBuri semibold font-12 mb-8 text-center' {...animatedItem1}>Trust</div>

        <div className='font-24 MaruBuri semibold text-center' {...animatedItem2}>기쁜마음치과의 믿음</div>

        <div className='font-14 MaruBuri semibold mt-10 mb-40 line-22 c-primary text-center' {...animatedItem3}>현재 서울과 국내의 다양한 지역에서뿐만<br/>아니라 캐나다와 미국에서 또한<br/>환자분들이 방문해주고 계십니다.</div>

        <div className='flex-center relative'>
          <div className='slideCover' onClick={sliderClick}>
            <StyledSlider ref={slideRef} {...options}>
              {
                Section9List.map((post, key) => (
                  <div className='slide-item mx-10' key={`slide9-post-key-${key}`}>
                    {
                      post.map((item, idx) => (
                        <div className='post_item py-30 px-20 mb-18' key={`slide9-item-${idx}`}>
                          <div className='c-black01 font-18 mb-20'>{item.name}</div>
                          <div className='keep-all c-black02 font-14 line-20'>{item.content}</div>
                        </div>
                      ))
                    }
                  </div>
                ))
              }
            </StyledSlider>
          </div>

        </div>

      </div>
    </Container>
  )
}