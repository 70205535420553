import React from 'react';
import styled from 'styled-components';
import Footer from './Footer';
import Header from './Header';

const Container = styled.div`

`

function LayoutContainer({ children }) {
  return (
    <>
        <Container id="#wrap">
          <Header/>

          <>
            {children}
          </>

          <Footer/>
        </Container>
    </>
  );
}

export default LayoutContainer;
