import { dentist_place_background, dentist_place_location, dentist_place_mark } from '@/assets'
import { PageTitle } from '@/components'
import useOnePageScroll from '@/hooks/useOnePageScroll'
import { useEffect } from 'react'
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 80px;

  .innerSection {
    padding: 160px 0;
    width: 1180px;
  }

  #map {
    width: 1180px;
    height: 600px;
  }
`

const { kakao } = window;

export default function Page () {

  useEffect(() => {
		var container = document.getElementById('map');
		var options = {
			center: new kakao.maps.LatLng(37.4937000, 127.028329),
			level: 3
		};

		var map = new kakao.maps.Map(container, options);

    var imageSrc = dentist_place_mark
    var imageSize = new kakao.maps.Size(152, 58);

    var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize);
    var markerPosition = new kakao.maps.LatLng(37.4937000, 127.028329);

    var marker = new kakao.maps.Marker({
      position: markerPosition,
      image: markerImage // 마커이미지 설정 
    });

    marker.setMap(map); 
  }, [])

  
  const pageScrollItem = useOnePageScroll()


  return (
    <Container className='flex-col align-center' {...pageScrollItem}>
      <PageTitle
        nav="치과소개"
        sub="오시는 길"
        background={dentist_place_background}
        title="오시는 길"
        subTitle="기쁜마음치과 오시는 길 안내"
      />


      <div className='innerSection' id="anker">
        <div id="map" className='w-full mb-68'>
          
        </div>

        <img src={dentist_place_location} className="w-full"/>
      </div>
    </Container>
  )
}