import {  main_section3_m_main } from '@/assets'
import { useScrollFadeIn } from '@/hooks'
import styled from 'styled-components'


const Container = styled.div`
  padding-bottom: 60px;

  img {
    max-width: calc(100vw - 40px);
  }
`


export default function Section3 () {

  const animatedItem1 = useScrollFadeIn() 
  
  return (
    <Container className='flex-col flex-center w-full'>
      <img src={main_section3_m_main} {...animatedItem1}/>
    </Container>
  )
}