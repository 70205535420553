import styled from 'styled-components'

import { header_logo } from '@/assets'
import { Link, NavLink } from 'react-router-dom'
import { getTheme } from '@/styles/theme'

const Container = styled.div`
  width: 100%;
  height: 80px;
  background-color: #fff;
  border-bottom: 1px solid #CCCCCC;
  box-shadow: 0px 3px 10px rgba(138, 116, 102, 0.05);
  position: fixed;
  top: 0;

  .logo {
    width: 182px;
    height: 50px;
  }

  z-index: 99;
  .innerHeader {
    width: 1380px; 
    height: 100%;
  }

  .header_button {
    width: 180px;
    height: 80px;

    background-color: ${getTheme('primary')};
  }

  .header_item {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header_menu {
    display: none;
    top: 78px;
    left: -20px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }



  .header_menu1:hover { display: block !important; }
  .header_menu2:hover { display: block !important; }
  .header_menu3:hover { display: block !important; }

  .header_item1:hover { 
    .header_menu1 { display: block !important; }
  }

  .header_item2:hover { 
    .header_menu2 { display: block !important; }
  }

  .header_item3:hover { 
    .header_menu3 { display: block !important; }
  }
`

const StyledLink = styled(NavLink)`
  display: block;

  :hover {
    color: ${getTheme('primary')};
  }
`

export default function PC () {

  return (
    <Container className='flex-center'>
      <div className='innerHeader flex-center'>
        <a href='/'>
          <img className='logo' src={header_logo}/>
        </a>

        <div className='flex-1'></div>

        <div className='header_item header_item1 relative mr-72'>
          <StyledLink className="" to='/dentist/staff'>치과소개</StyledLink>

          <div className='header_menu1 header_menu px-20 py-28 absolute' style={{width: '200px'}}>
            <StyledLink to='/dentist/staff' className='mb-20'>의료진</StyledLink>
            <StyledLink to='/dentist/facility' className='mb-20'>내부시설</StyledLink>
            <StyledLink to='/dentist/place'>오시는 길</StyledLink>
          </div>
        </div>

        <div className='relative header_item header_item2 mr-72'>
          <StyledLink className="" to='/implant/implant'>임플란트</StyledLink>

          <div className='header_menu2 header_menu px-20 py-28 absolute' style={{width: '280px'}}>
            <StyledLink to='/implant/implant' className='mb-20'>임플란트</StyledLink>
            <StyledLink to='/implant/bone' className='mb-20'>자가골 블록뼈 이식・앞니 임플란트</StyledLink>
            <StyledLink to='/implant/MaxillarySinusFloorElevation' className='mb-20'>상악동 거상・비강 거상</StyledLink>
            <StyledLink to='/implant/complications'>임플란트 부작용 치료</StyledLink>
          </div>
        </div>

        <div className='header_item header_item3 relative mr-72'>
          <StyledLink className="" to='/periodontalsurgery/gummy'>치주수술</StyledLink>
          
          <div className='header_menu3 header_menu px-20 py-28 absolute' style={{width: '266px'}}>
            <StyledLink to='/periodontalsurgery/gummy' className='mb-20'>심미 치관 연장술・거미 스마일</StyledLink>
            <StyledLink to='/periodontalsurgery/root' className='mb-20'>잇몸이식</StyledLink>
            <StyledLink to='/periodontalsurgery/crown' className='mb-20'>치관연장술</StyledLink>
            <StyledLink to='/periodontalsurgery/rapid' className='mb-20'>급속 교정 수술</StyledLink>
            <StyledLink to='/periodontalsurgery/forced' className='mb-20'>매복치 교정 수술</StyledLink>
            <StyledLink to='/periodontalsurgery/depigmentation'>잇몸미백</StyledLink>
          </div>
        </div>
        
        <div className='relative mr-72'>
          <StyledLink className="header_item4" to='/periodontaldiseasetreatment'>치주질환 치료</StyledLink>
        </div>
        
        <div className='relative'>
          <StyledLink className="header_item5" to='/aestheticprosthodontics'>심미보철</StyledLink>
        </div>


        <div className='flex-1'></div>

        <div className='header_button pointer flex-center c-white font-18 medium' onClick={() => {
          window.location.href = '/#info'

          let element = document.getElementById('main_info')
          let top = element.getBoundingClientRect().top + window.scrollY - 100;

          window.scrollTo({
            top,
            behavior: 'smooth'
          });

        }}>
          진료안내
        </div>
      
      </div>
    </Container>
  )
}