import styled from 'styled-components'

import Section1 from '@/ui/Main/Section1/pc'
import Section2 from '@/ui/Main/Section2/pc'
import Section3 from '@/ui/Main/Section3/pc'
import Section4 from '@/ui/Main/Section4/pc'
import Section5 from '@/ui/Main/Section5/pc'
import Section6 from '@/ui/Main/Section6/pc'
import Section7 from '@/ui/Main/Section7/pc'
import Section8 from '@/ui/Main/Section8/pc'
import Section9 from '@/ui/Main/Section9/pc'
import Section10 from '@/ui/Main/section10/pc'
import { FloatingMenu } from '@/components'
import { useEffect, useRef, useState } from 'react'
import { useScroll } from '@/hooks'


const Container = styled.div`
  padding-top: 80px;


`


export default function PC ({ Section2List, Section7List, Section9List }) {


  return (
    <Container className=''>
      <Section1/>

      <Section2 Section2List={Section2List}/>

      <Section3/>
      <Section4/>
      <Section5/>
      <Section6/>
      <Section7 Section7List={Section7List}/>
      <Section8/>
      <Section9 Section9List={Section9List}/>
      <Section10/>

      <FloatingMenu/>
    </Container>
  )
}