import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useRef } from 'react';
import { slide_big_next, slide_big_prev } from '@/assets';
import { useScrollFadeIn } from '@/hooks';

const Container = styled.div`
  padding: 160px 0;
  width: 100%;
  overflow-x: hidden;

  .slideCover {
    width: 1180px;
  }

  .innerSection {
  }

  .slidePrev {
    z-index: 1;
    top: 200px;
    left: -100px;
  }
  .slideNext {
    z-index: 1;
    top: 200px;
    right: -100px;
  }
`


const StyledSlider = styled(Slider)`
  width: 100%;

  .slick-list {
    width: 100%;
  }

  .post_item {
    transition: .2s;
    width: 380px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .post_item:hover {
    border: 1px solid #B33936;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }
`

export default function Section9 ({ Section9List }) {
  const options = {
    className: "slider variable-width",
    arrows:false,
    speed: 100,
    infinite: true,
    cssEase:'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
  };


  const slideRef = useRef()

  const animatedItem1 = useScrollFadeIn() 
  const animatedItem2 = useScrollFadeIn() 
  const animatedItem3 = useScrollFadeIn() 

  return (
    <Container className='flex-col align-center'>
      <div className='innerSection relative'>

        <div className='c-brown02 MaruBuri semibold font-22 mb-18 text-center' {...animatedItem1}>Trust</div>

        <div className='font-38 MaruBuri semibold text-center' {...animatedItem2}>기쁜마음치과의 믿음</div>

        <div className='font-18 MaruBuri semibold mt-28 mb-68 line-28 c-primary text-center' {...animatedItem3}>현재 서울과 국내의 다양한 지역에서뿐만 아니라<br/>캐나다와 미국에서 또한 환자분들이 방문해주고 계십니다.</div>

        <div className='flex-center relative'>
          <img className='slidePrev absolute pointer' onClick={() => slideRef.current.slickPrev()} src={slide_big_prev}/>

          <div className='slideCover'>
            <StyledSlider ref={slideRef} {...options}>
              {
                Section9List.map((post, key) => (
                  <div className='slide-item mr-20' key={`slide9-post-key-${key}`}>
                    {
                      post.map((item, idx) => (
                        <div className='post_item py-42 px-32 mb-18' key={`slide9-item-${idx}`}>
                          <div className='c-black01 font-22 mb-28'>{item.name}</div>
                          <div className='keep-all c-black02 line-24'>{item.content}</div>
                        </div>
                      ))
                    }
                  </div>
                ))
              }
            </StyledSlider>
          </div>

          <img className='slideNext absolute pointer' onClick={() => slideRef.current.slickNext()} src={slide_big_next}/>
        </div>

      </div>
    </Container>
  )
}