import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from 'react';
import { slide_next, slide_next_primary, slide_prev, slide_prev_primary } from '@/assets';
import { useScrollFadeIn } from '@/hooks';



const Container = styled.div`
  padding: 170px 0;
  
  .innerSection {
    padding-left: 370px;
  }

  .slideCover {
    height: 600px;
    max-width: calc(100% - 480px - 10vw);
    padding-top: 76px;
  }

  .let {
    letter-spacing: -1.5px;
  }

  
  
  .slidePrev, .slideNext {
    width: 40px;
    height: 40px;
  }
`

const StyledSlider = styled(Slider)`
  .postTitle {
    display: none;
  }
  .postImgBox {
    transition: .3s;
  }

  .slide-item:hover {

    .postImgBox {
      padding: 0;
    }

    .postImg {
      border: 1px solid #B33936;
      filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
    }

    .postTitle { display: block; }
  }
`



export default function Section7 ({ Section7List }) {
  const options = {
    className: "slider variable-width",
    arrows:false,
    speed: 100,
    infinite: true,
    cssEase:'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
  };


  const slideRef = useRef()

  const animatedItem1 = useScrollFadeIn() 
  const animatedItem2 = useScrollFadeIn() 
  const animatedItem3 = useScrollFadeIn() 
  const animatedItem4 = useScrollFadeIn() 

  const [nextHover, setNextHover] = useState(false)
  const [prevHover, setPrevHover] = useState(false)

  return (
    <Container>
      <div className='innerSection w-full'>
        <div className='flex'>
          <div className='' style={{width: '480px', marginRight: '20px'}}>
            <div className='c-brown02 MaruBuri semibold font-22 mb-18' {...animatedItem1}>Value & Principle</div>

            <div className='font-38 MaruBuri semibold' {...animatedItem2}>가치, 그리고 원칙</div>

            <div className='font-18 MaruBuri semibold mt-28 mb-50 line-28 c-primary' {...animatedItem3}>28년이라는 시간 동안 첫째도 실력,<br/>둘째도 실력으로만 왔습니다.</div>

            <div className='font-20 line-32 let' {...animatedItem4}>
              그 결과, 한국인 최초로 세계 3대 치의학 저널인 JPIO를 포함<br/>
              하여 권위있는 임플란트 학술지에 논문을 기재하는 영광을<br/>
              얻을 수 있었습니다.<br/><br/>

              이렇듯 기쁜마음치과의 가치는 “최고의 실력”입니다.<br/>
              저희의 가치를 기대하고 찾아와주신 만큼 대표원장인 제가 직접<br/>
              진료하는 것을 원칙으로 삼고 있으며, 허위 과대광고나 과잉<br/>
              진료 또한 절대로 하지 않고 있습니다.<br/><br/>

              최상의 진료 환경을 위해 각 방으로 분리된 1인 진료실 시스템,<br/>
              그리고 하루 15인 한정의 진료 원칙 역시 변함없이 지키고<br/>
              있습니다. 
            </div>
        
          
          </div>

          <div className='flex-1' style={{ width: '10vw'}}/>

          <div className='slideCover relative'>
            <StyledSlider ref={slideRef} {...options}>
              {
                Section7List.map((post, key) => (
                  <div className='slide-item mr-28' key={`slide7-post-key-${key}`}>
                    <div className='pt-42 postImgBox'>
                      <img src={post.img} className='postImg'/>
                    </div>

                    <div className='postTitle keep-all medium font-14 mt-20 c-primary line-24' style={{width: '360px'}}>{post.title}</div>
                  </div>
                ))
              }
            </StyledSlider>
            <div className='absolute flex align-center ' style={{bottom: '-58px'}}>
              <img className='slidePrev mr-30 pointer' onClick={() => slideRef.current.slickPrev()} src={prevHover ? slide_prev_primary : slide_prev_primary} onMouseOver={() => setPrevHover(true)} onMouseLeave={() => setPrevHover(false)}/>
              <img className='slideNext pointer' onClick={() => slideRef.current.slickNext()} src={nextHover ? slide_next_primary : slide_next_primary} onMouseOver={() => setNextHover(true)} onMouseLeave={() => setNextHover(false)}/>
            </div>
          </div>
        </div>

      </div>
    </Container>
  )
}