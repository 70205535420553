import { implant_background, implant_implant_section1, implant_implant_section2, implant_implant_section3_background, implant_implant_section3_item1, implant_implant_section3_item2, implant_implant_section3_item3, implant_implant_section3_logo1, implant_implant_section3_logo2, periodontalsurgery_background, periodontalsurgery_crown_m_section1, periodontalsurgery_crown_m_section2, periodontalsurgery_crown_section1, periodontalsurgery_crown_section2, periodontalsurgery_gummy_section1, periodontalsurgery_gummy_section2, slide_big_next, slide_next, slide_next_primary, slide_prev, slide_prev_primary } from '@/assets'
import { GridBox, PageTitle } from '@/components'
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from 'react';
import useOnePageScroll from '@/hooks/useOnePageScroll';

const Container = styled.div`
  padding-top: 60px;
  
  .innerSection {
    padding: 60px 20px;

    
    .slideCover {
      width: calc(100%);
    }
     
    .btnBox {
      top: 50px;
      right: 0;
      
      img {
        width: 20px;
      }
    }
  }

  .section {    
    margin-bottom: 60px;


    .content {
      letter-spacing: -1px;
    }
   
  }



  .logo1 {
    top: 32px;
    left: 32px;
  }

  .logo2 {
    bottom: 38px;
    right: 38px;
  }

  .lowImg {
    width: 100%;
  }

  .text {
    height: 24px;
  }
`



const StyledSlider = styled(Slider)`
  width: 100%;


  .slide-item {
    width: 100% !important;

    .img {
      width: calc(50vw - 32px);
    }

    .arrow {
      width: 12px;
    }
  }
`

const TotalPage = 18

export default function Page () {
  
  const pageScrollItem = useOnePageScroll()

  const options = {
    className: "slider variable-width",
    arrows:false,
    speed: 200,
    infinite: true,
    cssEase:'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    draggable: false,
  };

  const ref = useRef()

  return (
    <Container className='flex-col align-center' {...pageScrollItem}>
      <PageTitle
        nav="치주수술"
        sub="치관 연장술"
        background={periodontalsurgery_background}
        title="치관 연장술"
        isMobile={true}
        subTitle="Crown Lengthening"
      />

      <div className='innerSection w-full' id="anker">
        <div className='section'>
          <div className='mb-48 w-full'>
            <div className='MaruBuri font-12 c-brown02 mb-8'>Crown Lengthening</div>
            <div className='mb-20 MaruBuri font-24 line-32 semibold'>치관 연장술</div>

            <div className='c-black02 font-14 line-22 content keep-all mb-40'>
              치관 연장술이란 치아의 임상 치관(Clinical Crown: 잇몸 밖으로 보이는 치아의 머리 부분) 길이를 길게 하는 치주수술입니다. 충치가 잇몸 속까지 깊게 진행되어 충치 치료가 어렵거나, 치아의 임상 치관이
              짧아 크라운의 유지력이 떨어져 빠질 염려가 있을때 치관 연장술이 필요합니다. 치관 연장술은 단순히 잇몸만 절제하는 것이 아닌, 잇몸을 열고 하방의 치조골(잇몸뼈) 길이와 형태를 조절해야 하며, 보철물은
              치조골(잇몸뼈)로부터 2~3mm 또는 그 이상 떨어져 있어야 해당 부위에 치주염이 발생하지 않습니다.
            </div>
          </div>
          
          <img className='w-full' src={periodontalsurgery_crown_m_section1}/>

          <div className='keep-all font-10 c-black03 medium mt-20'>
          ▶ ︎타치과에서 치료받은 아래 앞니 4개 치아 보철물의 생물학적 폭경 침범으로 인해 잇몸 염증 및 통증 호소하여 치관연장술 시행과 보철물을 다시 제작한 후 치주염이 사라졌습니다.
          </div>
        </div>


        <div className='section'>
          <div className='w-full mb-48'>
            <div className='MaruBuri font-12 c-brown02 mb-8'>Biologic Width Violation</div>
            <div className='mb-20 MaruBuri font-24 line-32 semibold'>생물학적 폭경 침범</div>

            <div className='c-black02 font-14 line-22 content keep-all mb-40'>
            치아 머리와 치조골 사이에 있는 잇몸 연조직 부위까지 크라운과 같은 보철물이 들어가면 잇몸 염증이나 치조골 파괴 등의 치주염이 발생하게 되는데, 이런 현상을 생물학적 폭경 침범
            (Biologic Width Violation)이라고 부릅니다. 생물학적 폭경 침범은 대개 충치가 잇몸 속 깊이 썩어 있어서 충치를 제거하고 치조골 근처까지 크라운을 제작하거나 짧은 치아를 길게 만들기 위해
            크라운을 잇몸 깊숙히 부착했을 때 발생하며, 이 때에도 치관 연장술이 필요합니다.
            </div>
          </div>
          
          <img className='w-full' src={periodontalsurgery_crown_m_section2}/>
        </div>


        <div className='w-full relative'>
          <div className='MaruBuri font-12 c-brown02 mb-8'>Treatment Case</div>
          <div className='flex align-center mb-20 '>
            <div className='MaruBuri font-24 line-32 semibold'>상세 치료사례</div>
            <div className='flex-1'/>
            <div className='flex align-center btnBox'>
              <img src={slide_prev_primary} className='mr-16 pointer' onClick={() => ref?.current?.slickPrev()}/>
              <img src={slide_next_primary} className='pointer' onClick={() => ref?.current?.slickNext()}/>
            </div>
          </div>

          <div className='slideCover'>
            <StyledSlider ref={ref} {...options}>
              {
                [...new Array(TotalPage/2)].map((post, key) => (
                  <div className='slide-item' key={`list2-post-key-${key}`}>
                    <div className='flex-center'>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontalSurgery/crown/before${key * 2 + 1}.png`}/>
                      <img className='mx-6 arrow' src={slide_big_next}/>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontalSurgery/crown/after${key * 2 + 1}.png`}/>
                    </div>
                    
                    <div className='flex-center mt-10'>
                      { key !== 7 ? <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontalSurgery/crown/before${key * 2 + 2}.png`}/> : <div className='img'/>}
                      { ![0, 3, 5, 7].includes(key) ? <img className='mx-6 arrow' src={slide_big_next}/> : <div className='pl-24'/>}
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/periodontalSurgery/crown/after${key * 2 + 2}.png`}/>
                    </div>
                  </div>
                ))
              }
            </StyledSlider>
          </div>
        </div>
      </div>
    </Container>
  )
}