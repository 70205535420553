import { main_section5_background, main_section5_doctor, main_section5_record } from '@/assets'
import { useScrollFadeIn } from '@/hooks'
import styled from 'styled-components'


const Container = styled.div`
  width: 100%;
  background-image: url(${main_section5_background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;

  height: 1335px;

  padding-top: 480px;

  margin-top: -360px;

  .innerSection {
    width: 1180px;
    padding-right: 30px;
  }

`


export default function Section5 () {
  const animatedItem1 = useScrollFadeIn() 
  const animatedItem2 = useScrollFadeIn() 
  const animatedItem3 = useScrollFadeIn() 

  return (
    <Container className='w-full flex-col flex-center'>
      <div className='innerSection flex' >
        <div className='mr-96'>
          <div {...animatedItem1}>
            <div className='font-22 MaruBuri semibold mb-18 c-brown02'>Yeong Sug Kim</div>

            <div className='font-38 MaruBuri semibold mb-68'>대표원장 이력</div>
          </div>
          <img src={main_section5_record} {...animatedItem2}/>
        </div>

        <img src={main_section5_doctor} {...animatedItem3}/>
      </div>
    </Container>
  )
}