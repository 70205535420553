import { main_section6_text, main_section8_background, main_section8_item1_after, main_section8_item1_before, main_section8_item2_after, main_section8_item2_before, main_section8_text } from '@/assets'
import { useScrollFadeIn } from '@/hooks'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'



const Container = styled.div`
  padding: 160px 0;
  background-color: #F1Eee5;
  .backImg {
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }

  .innerSection {
    width: 1180px;
  }

  .imgSide {
    img {
      width: 285px;
    }
  }

  .let {
    letter-spacing: -1.5px;
  }
`


export default function Section8 () {

  const animatedItem1 = useScrollFadeIn() 
  const animatedItem2 = useScrollFadeIn() 
  const animatedItem3 = useScrollFadeIn() 
  const animatedItem4 = useScrollFadeIn() 
  
  const animatedItem5 = useScrollFadeIn() 
  const animatedItem6 = useScrollFadeIn() 
  const animatedItem7 = useScrollFadeIn() 
  const animatedItem8 = useScrollFadeIn() 
  
  const animatedItem9 = useScrollFadeIn() 
  const animatedItem10 = useScrollFadeIn() 
  const animatedItem11 = useScrollFadeIn() 

  const navigate = useNavigate()

  return (
    <Container className='relative flex-col align-center'>
      <img className='absolute backImg' src={main_section8_background}/>
      <div className='innerSection relative'>
        <div className='c-brown02 MaruBuri semibold font-22 mb-18' {...animatedItem1}>Distinction</div>

        <div className='font-38 MaruBuri semibold' {...animatedItem2}>타 병원들과의 초격차</div>

        <div className='font-18 MaruBuri semibold mt-28 mb-68 line-28 c-primary' {...animatedItem3}>대학 병원들이 포기하는 고난이도의 질환도<br/>기쁜마음에서는 가능합니다.</div>


        <div className='w-full flex'>
          <div className='mr-40'>
            <div className='flex mb-48 imgSide' {...animatedItem4}>
              <img src={main_section8_item1_before}/>
              <img src={main_section8_item1_after}/>
            </div>

            <div className='font-22 mb-12' {...animatedItem5}>자가골 블록뼈 이식</div>
            <div className='font-20 c-black02 line-32 mb-50 let' {...animatedItem6}>
              자가골 블록뼈 이식은 우리 몸에 맞는, 가장 좋은 뼈이식 방법입니다. 하지만<br/>
              고도의 테크닉과 지식이 필요하기에 아직 국내에서는 자가골 이식을 제대로<br/>
              할 수 있는 병원이 극히 드뭅니다.
            </div>

            <img className='pointer' src={main_section8_text} {...animatedItem7} onClick={() => navigate('/implant/bone')}/>
          </div>

          <div className=''>
            <div className='flex mb-48 imgSide' {...animatedItem8}>
              <img src={main_section8_item2_before}/>
              <img src={main_section8_item2_after}/>
            </div>

            <div className='font-22 mb-12' {...animatedItem9}>잇몸 이식</div>
            <div className='font-20 c-black02 line-32 mb-50 let' {...animatedItem10}>
              잇몸이식은 그 무엇보다 심미성과 유지가 제일 중요합니다.<br/>
              기쁜마음치과에서는 심미성에 가장 효과적이라고 알려진 고난이도의<br/>
              CTG 술식을 더 자주 시행합니다.
            </div>

            <img className='pointer' src={main_section8_text} {...animatedItem11} onClick={() => navigate('/periodontalsurgery/root')}/>
          </div>
        </div>
      </div>
    </Container>
  )
}