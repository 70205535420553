import styled from "styled-components";
import useMobileCheck from "@/hooks/useMobileCheck";
import { default as Mobile } from "./mobile";
import { default as PC } from "./pc";

const Container = styled.div``;

export default function Page() {
  const isMobile = useMobileCheck();

  return <Container>{isMobile ? <Mobile /> : <PC />}</Container>;
}
