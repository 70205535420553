import { aestheticprosthodontics_m_section1_1, aestheticprosthodontics_m_section1_2, aestheticprosthodontics_m_section2, aestheticprosthodontics_section1, aestheticprosthodontics_section2, implant_background, implant_implant_section1, implant_implant_section2, implant_implant_section3_background, implant_implant_section3_item1, implant_implant_section3_item2, implant_implant_section3_item3, implant_implant_section3_logo1, implant_implant_section3_logo2, periodontaldiseasetreatment_section1, periodontaldiseasetreatment_section2, periodontalsurgery_background, periodontalsurgery_crown_section1, periodontalsurgery_crown_section2, periodontalsurgery_gummy_section1, periodontalsurgery_gummy_section2, slide_big_next, slide_next, slide_next_primary, slide_prev, slide_prev_primary } from '@/assets'
import { GridBox, PageTitle } from '@/components'
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from 'react';
import useOnePageScroll from '@/hooks/useOnePageScroll';

const Container = styled.div`
  padding-top: 60px;
  
  .innerSection {
    padding: 60px 20px;

    
    .slideCover {
      width: calc(100%);
    }
     
    .btnBox {
      top: 50px;
      right: 0;
      
      img {
        width: 20px;
      }
    }
  }

  .section {    
    margin-bottom: 60px;


    .content {
      letter-spacing: -1px;
    }
   
  }



  .logo1 {
    top: 32px;
    left: 32px;
  }

  .logo2 {
    bottom: 38px;
    right: 38px;
  }

  .lowImg {
    width: 100%;
  }

  .text {
    height: 24px;
  }
`



const StyledSlider = styled(Slider)`
  width: 100%;


  .slide-item {
    width: 100% !important;

    .img {
      width: calc(50vw - 32px);
    }

    .arrow {
      width: 12px;
    }
  }
`

const TotalPage = 20

export default function Page () {
  const pageScrollItem = useOnePageScroll()

  const options = {
    className: "slider variable-width",
    arrows:false,
    speed: 200,
    infinite: true,
    cssEase:'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    draggable: false,
  };

  const ref = useRef()

  return (
    <Container className='flex-col align-center' {...pageScrollItem}>
      <PageTitle
        nav="심미보철"
        sub="심미보철"
        background={periodontalsurgery_background}
        title="심미보철"
        subTitle="Aesthetic Prosthodontics"
        isMobile={true}
      />


      <div className='innerSection w-full' id="anker">
        <div className='section'>
          <div className='mb-20 w-full'>
            <div className='MaruBuri font-12 c-brown02 mb-8'>Aesthetic Prosthodontics</div>
            <div className='mb-20 MaruBuri font-24 line-32 semibold'>심미보철 치료란?</div>

            <div className='c-black02 font-14 line-22 content keep-all'>
            앞니는 한 사람의 외모와 인상에 중요한 역할을 합니다. 따라서 충치, 파절, 변색, 심한 교모, 부정교합, 상실 등의 원인으로 앞니 보철을 해야 하는 경우에는 신중을 기해야 합니다. 남아있는 치아를 최대한
            보존하면서 가장 심미적인 결과를 얻기 위해서는 개개인에 제일 적합한 치료 방법을 찾아야 합니다. 앞니는 치아 크기와 모양, 배열 등을 수복하는 것 외에도 치아 주변의 잇몸 상태(잇몸 선의 높이, 잇몸 색,
            잇몸뼈 의 돌출 여부 등)까지 같이 개선하면 치료 효과가 극대화 될 수 있는데, 저희 기쁜 마음치과에서는 이러한 전체적인 치료를 제공해 드립니다.
            </div>
          </div>
          
          <GridBox col={2} gap={24}>
            <img className='w-full' src={aestheticprosthodontics_m_section1_1}/>
            <img className='w-full' src={aestheticprosthodontics_m_section1_2}/>
          </GridBox>

          <div className='mt-20 medium font-10 c-black03'>▶ 앞니 올 세라믹 크라운 치료</div>
        </div>


        <div className='section'>
          <div className='w-full mb-48'>
            <div className='MaruBuri font-12 c-brown02 mb-8'>Process</div>
            <div className='mb-20 MaruBuri font-24 line-32 semibold'>치료과정</div>

            <div className='c-black02 font-14 line-22 content keep-all mb-40'>
            40대 여성 환자분의 치료 과정입니다. 오른쪽 위 앞니는 잇몸 이식과 임플란트 식립을 하였으며 좌측의 중ㆍ측절치와 우측 측절치에는 치관 연장술을 한 뒤 세라믹 크라운으로 심미성을 높였습니다.
            </div>
          </div>
          
          <img className='w-full' src={aestheticprosthodontics_m_section2}/>
        </div>


        <div className='w-full relative'>
          <div className='MaruBuri font-12 c-brown02 mb-8'>Treatment Case</div>
          <div className='flex align-center mb-20 '>
            <div className='MaruBuri font-24 line-32 semibold'>상세 치료사례</div>
            <div className='flex-1'/>
            <div className='flex align-center btnBox'>
              <img src={slide_prev_primary} className='mr-16 pointer' onClick={() => ref?.current?.slickPrev()}/>
              <img src={slide_next_primary} className='pointer' onClick={() => ref?.current?.slickNext()}/>
            </div>
          </div>

          <div className='slideCover'>
            <StyledSlider ref={ref} {...options}>
              {
                [...new Array(Math.ceil(TotalPage/2))].map((post, key) => (
                  <div className='slide-item' key={`list2-post-key-${key}`}>
                    <div className='flex-center'>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/aestheticprosthodontics/before${key * 2 + 1}.png`}/>
                      <img className='mx-6 arrow' src={slide_big_next}/>
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/aestheticprosthodontics/after${key * 2 + 1}.png`}/>
                    </div>
                    
                    {LabelList[key * 2] !== '' && <div className='flex-center'>
                      <div className='w-full label py-6 text-center c-white font-12 keep-all'>{LabelList[key * 2]}</div>
                    </div>}

                    <div className=' mb-10'/>

                    <div className='flex-center'>
                      { (key !== 2 && key !== 4 ) ? <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/aestheticprosthodontics/before${key * 2 + 2}.png`}/> : <div className='img'/>}
                      { (key !== 2 && key !== 4 &&  ![5, 7].includes(key)  ) ? <img className='mx-6 arrow' src={slide_big_next}/> : <div className='pl-24'/>}
                      <img className='img' src={`https://dentist-image-s3.s3.ap-northeast-2.amazonaws.com/aestheticprosthodontics/after${key * 2 + 2}.png`}/>
                    </div>

                    {LabelList[key * 2 + 1] !== '' && <div className='flex-center mb-10'>
                      <div className='w-full label py-6 text-center c-white font-12 keep-all'>{LabelList[key * 2 + 1]}</div>
                    </div>}
                  </div>
                ))
              }
            </StyledSlider>
          </div>
        </div>
      </div>
    </Container>
  )
}

const LabelList = [
  '',
  '오른쪽 위 앞니 잇몸이식 및 임플란트 , 좌측 중절치, 측절치 , 우측 측절치 치관 연장술 및 올 세라믹 크라운 40대 여성',
  '',
  '앞니 올 세라믹 크라운. 30대 여성',
  '',
  '앞니 치관 연장술 후 지르코니아 크라운. 60대 여성',
  '',
  '전악 보철로 낮아진 수직고경 회복 및 안모 심미성 개선. 60대 여성',
  '',
  '잇몸성형수술(심미치관연장술,Gummy Smile) 후 레진 수복. 10 대 여성',
  '',
  '치근 파절로 위 앞니 발치 후 보철물 제거, 잇몸 이식 & 지르코니아 크라운으로 브릿지 아래 앞니 치관 연장술 & 지르코니아 크라운',
  '앞니 라미네이트. 30 대 여성',
  '잇몸미백(잇몸 멜라닌 색소 제거), 잇몸성형수술(심미치관연장술, Gummy Smile) 후 지르코니아 크라운. 20 대 여성',
  '잇몸성형수술(심미치관연장술,Gummy Smile) 전 후',
  '잇몸성형수술 & 앞니 라미네이트 8년 후',
  '위 좌측 앞니 자가골 블록뼈 이식& 임플란트 , 치관 연장술, 올 세라믹 크라운',
  '앞니 치주수술 후 지르코니아 크라운',
  '잇몸미백(잇몸 멜라닌 색소 제거), 잇몸성형수술(심미치관연장술,Gummy smile) 후 지르코니아 크라운(위 앞니) 및 레진 수복(아래 앞니)',
  '치아 미백 & 지르코니아 크라운'
]