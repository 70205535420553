import { main_section5_background, main_section5_doctor, main_section5_m_record, main_section5_record } from '@/assets'
import { useScrollFadeIn } from '@/hooks'
import styled from 'styled-components'


const Container = styled.div`
  padding: 60px 0;
  width: 100%;
  background: linear-gradient(180deg, #F1EEE5 23.94%, rgba(241, 238, 229, 0) 72.35%), #F1EEE5;

`


export default function Section5 () {
  const animatedItem1 = useScrollFadeIn() 
  const animatedItem2 = useScrollFadeIn() 
  const animatedItem3 = useScrollFadeIn() 

  return (
    <Container className='w-full'>
      <div className='innerSection w-full px-20' >
        <div className=''>
          <img src={main_section5_m_record} {...animatedItem2}/>
        </div>
      </div>
    </Container>
  )
}