import styled from 'styled-components'


const Container = styled.div`
`


export default function NotFoundPage () {

  return (
    <Container>
      404 Not Found
    </Container>
  )
}