import { useState, useRef, useEffect } from 'react'

const useOnePageScroll = () => {
  const dom = useRef();

  const [active, setActive] = useState(window.scrollY !== 0)

  useEffect(() => {
    window.scrollTo({ top: 0 })
    return () => document.body.style= `overflow: auto`;
  }, []) 

  // useEffect(() => {
  //   let element = document.getElementById('page_title_section')
  //   console.log(element.scrollHeight)

  //   let top = element.scrollHeight;

  //   console.log(active, "tick")
  //   const tick = setTimeout(() => {
  //     scrollBlock()
  //   }, active ? 500 : 0);

  //   const scrollBlock = () => {
  //     console.log(active, "tick", window.scrollY, top)
  //     if(!active && window.scrollY < 3000) document.body.style= `overflow: hidden`;
  //     else document.body.style= `overflow: auto`;
  //     return () => document.body.style = `overflow: auto`
  //   }

  //   return () => clearTimeout(tick);
  // }, [active, window.scrollY])


  // function goSection2() {
  //   let element = document.getElementById('anker')
  //   let top = element.getBoundingClientRect().top + window.scrollY;
  //   window.scrollTo({
  //     behavior: 'smooth',
  //     top
  //   })
  // }

  function wheelHandle(e) {
    // let element = document.getElementById('page_title_section')
    // let top = element.scrollHeight;

    // console.log(e)
    // const { deltaY } = e

    // console.log(deltaY, top, window.scrollY)


    // if(window.scrollY > top * 5) {
    //   document.body.style= `overflow: auto`;
    //   return setActive(true)
    // }

    // // Scroll Down
    // if(deltaY > 0 && window.scrollY < top) {
    //   goSection2()
    //   setActive(true)
    // }

    // // Scroll Up
    // else if(deltaY < 0 && window.scrollY < top + 200) {
    //   window.scrollTo({
    //     behavior: 'smooth',
    //     top: 0
    //   })

    //   setActive(false)
    // }
  }

  return {
    ref: dom,
    onWheel: wheelHandle
  }
}

export default useOnePageScroll
