import { GridBox } from '@/components'
import { getTheme } from '@/styles/theme'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { main_section6_item1, main_section6_item2, main_section6_item3, main_section6_item4, main_section6_text, main_section6_text_hover, main_section6_item_hover1, main_section6_item_hover2, main_section6_item_hover3, main_section6_item_hover4 } from '@/assets'
import { useScrollFadeIn } from '@/hooks'


const Container = styled.div`
  padding: 160px 0;
`


export default function Section6 () {
  const animatedItem1 = useScrollFadeIn() 
  const animatedItem2 = useScrollFadeIn() 

  return (
    <Container className='flex-col align-center'>
      <div className='text-center mb-18 c-brown02 font-22 MaruBuri semibold' {...animatedItem1}>Excellence</div>
      
      <div className='text-center mb-68 font-38 MaruBuri semibold' {...animatedItem2}>진료과목</div>
      
      <GridBox col={4} gap={28} style={{width: '1108px'}}>
        {
          MenuList.map((post, key) => (
            <MenuItem {...post} key={`section6-grid-item-${key}`}/>
          ))
        }
      </GridBox>
    </Container>
  )
}



const MenuContainer = styled.div`
  width: 256px;
  height: 256px;

  padding: 28px;
  padding-top: 38px;

  transition: .5s;

  background-color: ${({active}) => active ? getTheme('primary') : '#F1F1F1'};
  
  .title {
    color: ${({active}) => active ? getTheme('white') : getTheme('black01')};
  }
`

const MenuItem = ({ img, hoverImg, link, title }) => {
  const [active, setActive] = useState(false)
  return (
    <MenuContainer 
      active={active} 
      onMouseOver={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      className='flex-col'
    >
      <div className='title mb-12 font-22'>{title}</div>

      <Link to={link}>
        <img src={active ? main_section6_text_hover : main_section6_text}/>
      </Link>

      <div className='flex-1'/>

      <div className='flex-end'>
        <img src={active ? hoverImg : img}/>
      </div>
    </MenuContainer>
  )
}



const MenuList = [
  {
    img: main_section6_item1,
    hoverImg: main_section6_item_hover1,
    link: '/implant/implant',
    title: '임플란트'
  },
  {
    img: main_section6_item2,
    hoverImg: main_section6_item_hover2,
    link: '/periodontalsurgery/gummy',
    title: '치주수술'
  },
  {
    img: main_section6_item3,
    hoverImg: main_section6_item_hover3,
    link: '/periodontaldiseasetreatment',
    title: '치주질환 치료'
  },
  {
    img: main_section6_item4,
    hoverImg: main_section6_item_hover4,
    link: '/aestheticprosthodontics',
    title: '심미보철'
  }
]