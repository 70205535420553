import { floating_menu1, floating_menu2, floating_menu3, floating_menu4, floating_menu5, floating_popup, floating_top } from '@/assets'
import { useClickOutside } from '@/hooks'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'


const Container = styled.div`
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 10;

  img {
    cursor: pointer;
  }

  .popup {
    right: 120px;
    top: 219px;
  }

  a, .menuImg {
    width: 110px;
    height: 110px;
  }
`


export default function FloatingMenu () {
  const [isOpen, setIsOpen] = useState(false)

  const ref = useRef()

  useClickOutside(ref, () => setIsOpen(false))
  
  return (
    <Container>
      <div className='flex-col align-center'>
        <a href='https://m.booking.naver.com/booking/13/bizes/224458/items/3133851' target={'_blank'}>
          <img className='menuImg' src={floating_menu1}/>
        </a>
        
        <Link to='/dentist/place'>
          <img className='menuImg' src={floating_menu2}/>
        </Link>

        <img className='menuImg' src={floating_menu3} onClick={() => setIsOpen(!isOpen)}/>
        
        <a href='https://blog.naver.com/yeongsgkim' target={'_blank'}>
          <img className='menuImg' src={floating_menu4}/>
        </a>

        <a href='tel:025340002'>
          <img className='menuImg' src={floating_menu5}/>
        </a>
        <img className='mt-40' src={floating_top} onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }}/>
        
        { isOpen && <img src={floating_popup} ref={ref} className="popup absolute"/> }
      </div>
    </Container>
  )
}