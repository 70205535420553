import { main_section4_background } from '@/assets'
import { useScrollFadeIn } from '@/hooks'
import styled from 'styled-components'


const Container = styled.div`
  img {
    width: 1180px !important;
  }
`


export default function Section4 () {

  const animatedItem1 = useScrollFadeIn() 
  return (
    <Container className='flex-center flex-col w-full'>
      <img src={main_section4_background} {...animatedItem1}/>
    </Container>
  )
}