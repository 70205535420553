import { main_section6_text, main_section8_item1_after, main_section8_item1_before, main_section8_item2_after, main_section8_item2_before, main_section8_text } from '@/assets'
import { GridBox } from '@/components'
import { useScrollFadeIn } from '@/hooks'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'



const Container = styled.div`
  padding: 60px 0;
  background-color: #F1Eee5;


  .innerSection {
  }

  .moreBtn {
    width: 110px;
  }
`


export default function Section8 () {

  const animatedItem1 = useScrollFadeIn() 
  const animatedItem2 = useScrollFadeIn() 
  const animatedItem3 = useScrollFadeIn() 
  const animatedItem4 = useScrollFadeIn() 
  
  const animatedItem5 = useScrollFadeIn() 
  const animatedItem6 = useScrollFadeIn() 
  const animatedItem7 = useScrollFadeIn() 
  const animatedItem8 = useScrollFadeIn() 
  
  const animatedItem9 = useScrollFadeIn() 
  const animatedItem10 = useScrollFadeIn() 
  const animatedItem11 = useScrollFadeIn() 

  const navigate = useNavigate()

  return (
    <Container className='relative flex-col align-center'>
      <div className='innerSection relative px-20'>
        <div className='c-brown02 MaruBuri semibold font-12 mb-8' {...animatedItem1}>Distinction</div>

        <div className='font-24 MaruBuri semibold' {...animatedItem2}>타 병원들과의 초격차</div>

        <div className='font-14 MaruBuri semibold mt-10 mb-40 line-22 c-primary' {...animatedItem3}>대학 병원들이 포기하는 고난이도의 질환도<br/>기쁜마음에서는 가능합니다.</div>


        <div className='w-full'>
          <div className=''>
            <div className='w-full mb-20' {...animatedItem4}>
              <GridBox col={2} gap={0}>
                <img className='w-full' src={main_section8_item1_before}/>
                <img className='w-full' src={main_section8_item1_after}/>
              </GridBox>
            </div>

            <div className='font-16 mb-8' {...animatedItem5}>자가골 블록뼈 이식</div>
            <div className='font-14 c-black02 line-22 mb-16 keep-all' {...animatedItem6}>
              자가골 블록뼈 이식은 우리 몸에 맞는, 가장 좋은 뼈이식 방법입니다. 하지만 고도의 테크닉과
              지식이 필요하기에 아직 국내에서는 자가골 이식을 제대로 할 수 있는 병원이 극히 드뭅니다.
            </div>

            <img className='pointer moreBtn' src={main_section8_text} {...animatedItem7} onClick={() => navigate('/implant/bone')}/>
          </div>

          <div className='mt-40'>
            <div className='flex mb-20' {...animatedItem8}>
              <GridBox col={2} gap={0}>
                <img className='w-full' src={main_section8_item2_before}/>
                <img className='w-full' src={main_section8_item2_after}/>
              </GridBox>
            </div>

            <div className='font-16 mb-8' {...animatedItem9}>잇몸 이식</div>
            <div className='font-14 c-black02 line-22 mb-16 keep-all' {...animatedItem10}>
              잇몸이식은 그 무엇보다 심미성과 유지가 제일 중요합니다. 기쁜마음치과에서는 심미성에 가장
              효과적이라고 알려진 고난이도의 CTG 술식을 더 자주 시행합니다.
            </div>

            <img className='pointer moreBtn'  src={main_section8_text} {...animatedItem11} onClick={() => navigate('/periodontalsurgery/root')}/>
          </div>
        </div>
      </div>
    </Container>
  )
}