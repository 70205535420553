import { footer_logo } from '@/assets'
import styled from 'styled-components'


const Container = styled.div`
  padding: 68px 0;
  background-color: #4A3E37;

  .innerFooter {
    width: 1380px;
  }

  .mt-20 {
    color: #81746B;
  }

  .copylight {
    color: #665950;
  }
`


export default function PC () {

  return (
    <Container className='flex-col align-center'>
      <div className='innerFooter'>
        <img src={footer_logo}/>

        <div className='mt-20 medium'>
          <div className='flex align-center mb-8'>
            <div className='mr-4'>기쁜마음치과</div>
            <div>김영숙</div>
          </div>

          <div className='flex align-center mb-8'>
            <div className='mr-12'>사업자번호 102-96-06754</div>
            <div className='mr-4'>T</div>
            <div>02 534 0002</div>
          </div>

          <div className='mb-8'>
            서울 서초구 서초대로 78길 44 나산 스위트 301호
          </div>

          <div className='copylight'>© 기쁜마음치과</div>
        </div>
      </div>
    </Container>
  )
}