import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import LayoutContainer from "../layouts/LayoutContainer";
import ErrorBoundary from "./ErrorBoundary";

import DentistStaffPage from "@/pages/dentist/staff";
import DentistFacilityPage from "@/pages/dentist/facility";
import DentistPlacePage from "@/pages/dentist/place";

import ImplantPage from "@/pages/implant/implant";
import ImplantBonePage from "@/pages/implant/bone";
import ImplantMaxillarySinusFloorElevationPage from "@/pages/implant/maxillarySinusFloorElevation";
import ImplantComplicationsPage from "@/pages/implant/complications";

import PeriodontalsurgeryGummyPage from "@/pages/periodontalsurgery/gummy";
import PeriodontalsurgeryRootpage from "@/pages/periodontalsurgery/root";
import PeriodontalsurgeryCrownPage from "@/pages/periodontalsurgery/crown";
import PeriodontalsurgeryRapidPage from "@/pages/periodontalsurgery/rapid";
import PeriodontalsurgeryForcedPage from "@/pages/periodontalsurgery/forced";
import PeriodontalsurgeryDepigmentationPage from "@/pages/periodontalsurgery/depigmentation";
import PeriodontaldiseasetreatmentPage from "@/pages/periodontaldiseasetreatment";
import AestheticprosthodonticsPage from "@/pages/aestheticprosthodontics";

import LoginPage from "@/pages/login";
import MainPage from "@/pages/_app";
import NotFoundPage from "@/pages/404";

export const AppRouter = () => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <LayoutContainer>
          <Routes>
            <Route exact path="/" element={<MainPage />} />
            {/* 치과소개 */}
            <Route path="/dentist/staff" element={<DentistStaffPage />} />
            <Route path="/dentist/facility" element={<DentistFacilityPage />} />
            <Route path="/dentist/place" element={<DentistPlacePage />} />
            <Route path="/implant/implant" element={<ImplantPage />} />
            <Route path="/implant/bone" element={<ImplantBonePage />} />
            <Route
              path="/implant/MaxillarySinusFloorElevation"
              element={<ImplantMaxillarySinusFloorElevationPage />}
            />
            <Route
              path="/implant/complications"
              element={<ImplantComplicationsPage />}
            />
            {/* 치주수술 */}
            <Route
              path="/periodontalsurgery/gummy"
              element={<PeriodontalsurgeryGummyPage />}
            />
            <Route
              path="/periodontalsurgery/root"
              element={<PeriodontalsurgeryRootpage />}
            />
            <Route
              path="/periodontalsurgery/crown"
              element={<PeriodontalsurgeryCrownPage />}
            />{" "}
            <Route
              path="/periodontalsurgery/rapid"
              element={<PeriodontalsurgeryRapidPage />}
            />{" "}
            <Route
              path="/periodontalsurgery/forced"
              element={<PeriodontalsurgeryForcedPage />}
            />{" "}
            <Route
              path="/periodontalsurgery/depigmentation"
              element={<PeriodontalsurgeryDepigmentationPage />}
            />
            <Route
              path="/periodontaldiseasetreatment"
              element={<PeriodontaldiseasetreatmentPage />}
            />{" "}
            <Route
              path="/aestheticprosthodontics"
              element={<AestheticprosthodonticsPage />}
            />
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </LayoutContainer>
      </BrowserRouter>
    </ErrorBoundary>
  );
};
