import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useScrollFadeIn, useScrollFadeInLTR } from '@/hooks';
import { slide_next_primary, slide_prev_primary } from '@/assets';
import { useRef } from 'react';


const Container = styled.div`
  padding: 60px 0;

  .innerSection {
    overflow: hidden;
  }

  .slideCover {
    width: calc(100%);
  }

  .keep-all {
    letter-spacing: -1.5px;
  }

  .arrow {
    width: 20px;
    height: 20px;
  }
`

const StyledSlider = styled(Slider)`
  .imgAfter {
    display: none;
  }

  
  .imageBox {
    .imgAfter { width: 210px; height: 150px; }
    .imgBefore  { width: 210px; height: 150px; }
  }

  .imageBox:hover {
    .imgAfter { display: block; }
    .imgBefore { display: none; }
  }
`

export default function Section2 ({ Section2List }) {

  const animatedItem1 = useScrollFadeIn() 
  const animatedItem2 = useScrollFadeIn() 
  const animatedItem3 = useScrollFadeIn() 
  const animatedItem4 = useScrollFadeIn() 
  const animatedItem5 = useScrollFadeIn() 
  const animatedItem6 = useScrollFadeIn() 
  const animatedItem7 = useScrollFadeInLTR() 
  const animatedItem8 = useScrollFadeInLTR() 
  const animatedItem9 = useScrollFadeInLTR() 

  
  const options = {
    className: "slider variable-width",
    arrows:false,
    speed: 100,
    infinite: false,
    cssEase:'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
  };

  const slide1ref = useRef()
  const slide2ref = useRef()
  const slide3ref = useRef()

  return (
    <Container className='relative' id="main_section_2">
      
      <div className='text-center MaruBuri semibold c-brown02 font-12 mb-10' {...animatedItem1}>Exceptional</div>

      
      <div className='font-24 MaruBuri semibold mb-20 text-center line-32' {...animatedItem2}>선진과학을 바탕으로 한<br/>풍부한 임상경험</div>
      
      
      <div className='font-14 MaruBuri semibold line-22 c-primary text-center mb-60' {...animatedItem3}>
        10명 중 6분은 치주/ 임플란트 수술<br/> 
        부작용과 대학병원 및 대형병원에서<br/> 
        치료하지 못하는 질환들을 호소하는 환자입니다.
      </div>

      <div className='innerSection pl-20 w-full'>
        <div className='flex-col mb-80'>
          <div {...animatedItem7}>
            <div className='' style={{width: '300px', marginRight: '150px'}}>
              
              <div className='c-brown02 MaruBuri semibold font-14 mb-10'>Implant</div>
              
              <div className='font-20 MaruBuri semibold line-26'>국내에 몇 없는<br/>자가골 이식<br/>임플란트 전문 치과</div>
            </div>
            
            <div className='flex align-center justify-end pr-20 mt-20 btnBox'>
              <img src={slide_prev_primary} className='mr-20 pointer arrow' onClick={() => slide1ref?.current?.slickPrev()}/>
              <img src={slide_next_primary} className='pointer arrow' onClick={() => slide1ref?.current?.slickNext()}/>
            </div>
          </div>

          <div className='slideCover mt-20' {...animatedItem4}>
            <StyledSlider {...options} ref={slide1ref}>
              {
                Section2List.slice(0, 8).map((post, key) => (
                  <div className='slide-item mr-10' key={`slide3-post-key-${key}`}>
                    <div className='imageBox'>
                      <img src={post.before} className='imgBefore'/>
                      <img src={post.after} className='imgAfter'/>
                    </div>

                    <div className='keep-all mt-10 c-black02 font-14 line-20' style={{width: '210px'}}>{post.text}</div>
                  </div>
                ))
              }
            </StyledSlider>
          </div>
        </div>

        <div className='flex-col mb-80'>
          
          <div {...animatedItem8}>
            <div className='' style={{width: '300px', marginRight: '150px'}}>
              
              <div className='c-brown02 MaruBuri semibold font-14 mb-10'>periodontal</div>
              
              <div className='font-20 MaruBuri semibold line-26'>치주가 아름다워야<br/>치아도 빛이납니다</div>
            </div>
                        
            <div className='flex align-center mt-20 justify-end pr-20 btnBox'>
              <img src={slide_prev_primary} className='mr-20 pointer arrow' onClick={() => slide2ref?.current?.slickPrev()}/>
              <img src={slide_next_primary} className='pointer arrow' onClick={() => slide2ref?.current?.slickNext()}/>
            </div>
          </div>

          <div className='slideCover mt-20' {...animatedItem5}>
            <StyledSlider {...options} ref={slide2ref}>
              {
                Section2List.slice(8, 12).map((post, key) => (
                  <div className='slide-item mr-10' key={`slide3-post-key-${key}`}>
                    <div className='imageBox'>
                      <img src={post.before} className='imgBefore'/>
                      <img src={post.after} className='imgAfter'/>
                    </div>

                    <div className='keep-all mt-10 font-14 c-black02 line-20' style={{width: '210px'}}>{post.text}</div>
                  </div>
                ))
              }
            </StyledSlider>
          </div>
        </div>

        <div className='flex-col' style={{marginBottom: '20px'}}>
          <div {...animatedItem9}>
            <div className='' style={{width: '400px', marginRight: '50px'}}>
              
              <div className='c-brown02 MaruBuri semibold font-14 mb-10'>Aesthetic Dentistry</div>
              
              <div className='font-20 MaruBuri semibold line-26'>환자의 밝은 미소를 <br/>되찾기 위한 <br/>기쁜마음치과의 노력</div>
            </div>
                        
            <div className='flex align-center mt-20 justify-end pr-20 btnBox'>
              <img src={slide_prev_primary} className='mr-20 pointer arrow' onClick={() => slide3ref?.current?.slickPrev()}/>
              <img src={slide_next_primary} className='pointer arrow' onClick={() => slide3ref?.current?.slickNext()}/>
            </div>
          </div>

          <div className='slideCover mt-20' {...animatedItem6}>
            <StyledSlider {...options} ref={slide3ref}>
              {
                Section2List.slice(12, 15).map((post, key) => (
                  <div className='slide-item mr-10' key={`slide3-post-key-${key}`}>
                    <div className='imageBox'>
                      <img src={post.before} className='imgBefore'/>
                      <img src={post.after} className='imgAfter'/>
                    </div>

                    <div className='keep-all mt-10 font-14 c-black02 line-20' style={{width: '210px'}}>{post.text}</div>
                  </div>
                ))
              }
            </StyledSlider>
          </div>
        </div>
      </div>


    </Container>
  )
}