import { main_section10_background, main_section10_map } from '@/assets'
import { useScrollFadeIn } from '@/hooks'
import styled from 'styled-components'


const Container = styled.div`
  padding: 160px 0;
  
  background-image: url(${main_section10_background});
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  .innerSection {
    width: 1380px;
    .title {
      border-bottom: 2px solid #cab08f;
    }
  }

  .section10_map {
    width: 680px;
    margin-left: -16px;
  }

`


export default function Section10 () {

  const animatedItem1 = useScrollFadeIn() 
  const animatedItem2 = useScrollFadeIn() 
  const animatedItem3 = useScrollFadeIn() 
  const animatedItem4 = useScrollFadeIn() 

  return (
    <Container className='flex-col align-center' id="main_info">
      <div className='innerSection relative'>
        
        <div className='c-brown02 MaruBuri semibold font-22 mb-18' {...animatedItem1}>Information</div>

        
        <div className='font-38 MaruBuri semibold c-white' {...animatedItem2}>기쁜마음치과 진료안내</div>

        
        <div className='font-18 MaruBuri semibold mt-28 mb-68 line-28 c-brown01' {...animatedItem3}>기쁜마음치과에서는 치료가 안 되어서, 혹은 부작용이 생겨서 세 번, 네 번<br/>번거롭게 치과에 방문할 필요가 없으십니다.</div>
      
        <div className='flex-center' {...animatedItem4}>
          <img className='section10_map' src={main_section10_map}/>

          <div className='ml-80 flex-1'>
            
            <div className='title c-brown01 pb-20 mb-28 font-24 MaruBuri bold'>진료시간</div>
          
            <div className='c-white font-20 mb-4'>평일</div>
            <div className='c-brown01 font-18 mb-28'>오전 9시 30분 ~ 오후 6시 30분</div>
          
            <div className='c-white font-20 mb-4'>토요일</div>
            <div className='c-brown01 font-18 mb-28'>오전 9시 30분 ~ 오후 2시 (점심X)</div>
          
            <div className='c-white font-20 mb-4'>점심시간</div>
            <div className='c-brown01 font-18 mb-28'>오후 1시 ~ 오후 2시</div>
          
            <div className='c-brown02'>일요일, 수요일, 공휴일은 휴진입니다. 공휴일 있는 주는 수요일 정상 진료합니다.</div>
          </div>
        </div>
      </div>
    </Container>
  )
}